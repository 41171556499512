// src/components/MapDisplay.tsx

import React from 'react';
import InteractiveMapCloropleth from '../Interactive map Components/InteractiveMapCloropleth'; // Asegúrate de importar tu componente de mapa correctamente

const CostCalculatorMapSupport: React.FC = () => {
  return (
    <div>
      <InteractiveMapCloropleth />
    </div>
  );
};

export default CostCalculatorMapSupport;
