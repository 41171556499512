import React, { useState, useEffect } from "react";
import styles from "../../styles/Customer Journey styles/MoverCustomer.module.css";
import defaultAvatar from "../../assets/avatarPlaceholder.png";
import switzerland1 from "../../assets/ch-img1.jpeg";
import switzerland2 from "../../assets/ch-img2.jpeg";
import switzerland3 from "../../assets/ch-img3.jpeg";
import switzerland4 from "../../assets/ch-img4.jpg";
import switzerland5 from "../../assets/ch-img4.jpg";
import {
  FaCheckCircle,
  FaChevronDown,
  FaChevronUp,
  FaQuestionCircle,
  FaRecordVinyl,
  FaVideo,
  FaPhoneAlt,
  FaSpotify,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Step {
  id: number;
  key: string;
  title: string;
  description: string;
  completed: boolean;
  badge?: string;
  image: string;
  completedText: string;
  actions?: string[];
  icon?: string;
}

interface Notification {
  id: number;
  message: string;
  image?: string;
}

interface IndividualData {
  name: string;
  moveInfo: {
    moveType: string;
  };
  avatarUrl?: string;
}

interface CompanyData {
  name: string;
  avatar?: string;
}

const MoverCustomer: React.FC = () => {
  const { t, i18n } = useTranslation("global");

  const [steps, setSteps] = useState<Step[]>([]);
  const [activeStep, setActiveStep] = useState<number | null>(null);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [individualName, setIndividualName] = useState<string>("");
  const [moveType, setMoveType] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [avatarUrl, setAvatarUrl] = useState<string>(""); //(defaultAvatar);
  const [companyAvatarUrl, setCompanyAvatarUrl] = useState<string | null>(null);

  const [showBookCall, setShowBookCall] = useState<boolean>(false);

  const getToken = () => {
    return localStorage.getItem("token") || "";
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = getToken();
      try {
        const responseIndividual = await fetch(
          "https://api.relotu.com/individuals",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-relotu-access": token,
            },
          }
        );

        if (responseIndividual.ok) {
          const dataIndividual: IndividualData =
            await responseIndividual.json();

          setIndividualName(dataIndividual.name);
          setMoveType(dataIndividual.moveInfo.moveType);
          if (dataIndividual.avatarUrl) {
            setAvatarUrl(dataIndividual.avatarUrl);
          }
        } else {
          console.error("Error fetching individual data");
          return;
        }

        const responseCompany = await fetch(
          "https://api.relotu.com/individuals/company",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-relotu-access": token,
            },
          }
        );

        if (responseCompany.ok) {
          const dataCompany: CompanyData = await responseCompany.json();
          if (dataCompany.name) {
            setCompanyName(dataCompany.name);
          }
          if (dataCompany.avatar) {
            setCompanyAvatarUrl(dataCompany.avatar);
          } else {
            setCompanyAvatarUrl(null);
          }
        } else {
          console.error("Error fetching company data");
          return;
        }
      } catch (error) {
        console.error("Error:", error);
        return;
      }
    };

    fetchData();
  }, []);

  const generateSteps = (isCompanyAvatarMissing: boolean) => {
    const baseSteps: Step[] = [
      {
        id: 1,
        key: "receiveOffer",
        title: t("moverCustomer.steps.receiveOffer.title"),
        description: t("moverCustomer.steps.receiveOffer.description"),
        completed: true,
        badge: t("moverCustomer.steps.receiveOffer.badge"),
        image: switzerland1,
        completedText: t("moverCustomer.steps.receiveOffer.completedText"),
        actions: t("moverCustomer.steps.receiveOffer.actions", {
          returnObjects: true,
        }) as string[],
        icon: "📄",
      },
      {
        id: 2,
        key: "askQuestions",
        title: t("moverCustomer.steps.askQuestions.title"),
        description: t("moverCustomer.steps.askQuestions.description"),
        completed: false,
        badge: t("moverCustomer.steps.askQuestions.badge"),
        image: switzerland2,
        completedText: t("moverCustomer.steps.askQuestions.completedText"),
        actions: t("moverCustomer.steps.askQuestions.actions", {
          returnObjects: true,
        }) as string[],
        icon: "💬",
      },
      {
        id: 3,
        key: "accessVideos",
        title: t("moverCustomer.steps.accessVideos.title"),
        description: t("moverCustomer.steps.accessVideos.description"),
        completed: false,
        badge: t("moverCustomer.steps.accessVideos.badge"),
        image: switzerland3,
        completedText: t("moverCustomer.steps.accessVideos.completedText"),
        actions: t("moverCustomer.steps.accessVideos.actions", {
          returnObjects: true,
        }) as string[],
        icon: "🎥",
      },
      {
        id: 4,
        key: "completeTasks",
        title: t("moverCustomer.steps.completeTasks.title"),
        description: t("moverCustomer.steps.completeTasks.description"),
        completed: false,
        badge: t("moverCustomer.steps.completeTasks.badge"),
        image: switzerland5,
        completedText: t("moverCustomer.steps.completeTasks.completedText"),
        actions: t("moverCustomer.steps.completeTasks.actions", {
          returnObjects: true,
        }) as string[],
        icon: "📋",
      },
      {
        id: 5,
        key: "bookCall",
        title: t("moverCustomer.steps.bookCall.title"),
        description: t("moverCustomer.steps.bookCall.description"),
        completed: false,
        badge: t("moverCustomer.steps.bookCall.badge"),
        image: switzerland4,
        completedText: t("moverCustomer.steps.bookCall.completedText"),
        actions: t("moverCustomer.steps.bookCall.actions", {
          returnObjects: true,
        }) as string[],
        icon: "📞",
      },
    ];

    if (isCompanyAvatarMissing) {
      const financeStep: Step = {
        id: 6, // Asegúrate de que este ID no está duplicado
        key: "getToKnowFinances",
        title: t("moverCustomer.steps.getToKnowFinances.title"),
        description: t("moverCustomer.steps.getToKnowFinances.description"),
        completed: false,
        badge: t("moverCustomer.steps.getToKnowFinances.badge"),
        image: switzerland5, // Usa una imagen adecuada
        completedText: t("moverCustomer.steps.getToKnowFinances.completedText"),
        actions: [t("moverCustomer.steps.getToKnowFinances.action")],
        icon: "💰",
      };

      
      const referralStep: Step = {
        id: 7, // Asegúrate de que este ID no está duplicado
        key: "referFriends",
        title: t("moverCustomer.steps.referFriends.title"),
        description: t("moverCustomer.steps.referFriends.description"),
        completed: false,
        badge: t("moverCustomer.steps.referFriends.badge"),
        image: switzerland2, // Usa una imagen adecuada
        completedText: t("moverCustomer.steps.referFriends.completedText"),
        actions: [t("moverCustomer.steps.referFriends.action")],
        icon: "🎉",
      };
      baseSteps.splice(3, 0, financeStep);
      baseSteps.splice(7, 0, referralStep);
    }

    return baseSteps;
  };

  useEffect(() => {
    setSteps((prevSteps) => {
      const isCompanyAvatarMissing = !companyAvatarUrl;
      const newSteps = generateSteps(isCompanyAvatarMissing);

      return newSteps.map((step) => {
        const prevStep = prevSteps.find((s) => s.id === step.id);
        return {
          ...step,
          completed: prevStep ? prevStep.completed : step.completed,
        };
      });
    });
  }, [i18n.language, companyName, companyAvatarUrl]);

  useEffect(() => {
    if (
      moveType.toLowerCase() === "mover" ||
      moveType.toLowerCase().includes("commuter")
    ) {
      setSteps((prevSteps) =>
        prevSteps.map((step) =>
          step.id === 1
            ? { ...step, completed: true }
            : { ...step, completed: false }
        )
      );
    } else {
      setSteps((prevSteps) =>
        prevSteps.map((step) => ({ ...step, completed: true }))
      );
    }
  }, [moveType]);

  const toggleStepCompletion = (id: number) => {
    setSteps((prevSteps) =>
      prevSteps.map((step) =>
        step.id === id ? { ...step, completed: !step.completed } : step
      )
    );

    const step = steps.find((s) => s.id === id);
    if (step) {
      if (!step.completed) {
        addNotification(
          t("moverCustomer.notifications.badgeObtained", {
            badge: step.badge,
          }),
          step.image
        );
      } else {
        addNotification(
          t("moverCustomer.notifications.badgeRemoved", {
            badge: step.badge,
          }),
          step.image
        );
      }
    }
  };

  const toggleExpand = (id: number) => {
    setActiveStep(activeStep === id ? null : id);
  };

  const addNotification = (message: string, image?: string) => {
    const id = Date.now();
    setNotifications((prev) => [...prev, { id, message, image }]);

    setTimeout(() => {
      setNotifications((prev) => prev.filter((n) => n.id !== id));
    }, 3000);
  };

  const calculateProgress = () => {
    const completedSteps = steps.filter((step) => step.completed).length;
    return Math.round((completedSteps / steps.length) * 100);
  };

  if (!individualName || !moveType || !companyName) {
    return null;
  }

  return (
    <div className={styles.moverCustomer}>
      {/* Notifications */}
      <div className={styles.notifications}>
        {notifications.map((notification) => (
          <div key={notification.id} className={styles.notification}>
            <span>{notification.message}</span>
            {notification.image && (
              <img
                src={notification.image}
                alt="Switzerland"
                className={styles.notificationImage}
              />
            )}
          </div>
        ))}
      </div>

      {/* Header with Box */}
      <header className={styles.header}>
        <div className={styles.headerBox}>
          {avatarUrl && (
            <img src={avatarUrl} alt="User Avatar" className={styles.avatar} />
          )}
          <h1 className={styles.title}>
            <span className={styles.titleIcon}>🇨🇭</span>
            {t("moverCustomer.header.welcome")}
          </h1>
          <p className={styles.motivationalMessage}>
            {individualName
              ? t("moverCustomer.header.motivationalMessage", {
                  individualName,
                  companyName,
                })
              : ""}
          </p>
        </div>
      </header>

      {/* Progress Tracker */}
      <section className={styles.progressTracker}>
        <h2 className={styles.sectionTitle}>
          {t("moverCustomer.progressTracker.title")}
        </h2>
        <div className={styles.progressBarContainer}>
          <div
            className={styles.progressBar}
            aria-valuenow={calculateProgress()}
            aria-valuemin={0}
            aria-valuemax={100}
            role="progressbar"
          >
            <div
              className={styles.progress}
              style={{ width: `${calculateProgress()}%` }}
            ></div>
          </div>
          <span className={styles.progressLabel}>
            {t("moverCustomer.progressTracker.progressLabel", {
              progress: calculateProgress(),
            })}
          </span>
        </div>
        <div className={styles.badges}>
          {steps.map(
            (step) =>
              step.completed &&
              step.badge && (
                <span key={step.id} className={styles.badge}>
                  {step.badge}
                </span>
              )
          )}
        </div>
      </section>

      {/* Interactive Steps */}
      <section className={styles.stepsSection}>
        <h2 className={styles.sectionTitle}>
          {t("moverCustomer.nextSteps.title")}
        </h2>
        <div className={styles.gridContainer}>
          {steps.map((step) => (
            <div key={step.id} className={styles.stepCard}>
              <div className={styles.stepHeader}>
                <button
                  className={styles.checkButton}
                  onClick={() => toggleStepCompletion(step.id)}
                  aria-label={
                    step.completed
                      ? t(`moverCustomer.steps.${step.key}.toggleIncomplete`, {
                          title: step.title,
                        })
                      : t(`moverCustomer.steps.${step.key}.toggleComplete`, {
                          title: step.title,
                        })
                  }
                  title={
                    step.completed
                      ? t(`moverCustomer.steps.${step.key}.toggleIncomplete`, {
                          title: step.title,
                        })
                      : t(`moverCustomer.steps.${step.key}.toggleComplete`, {
                          title: step.title,
                        })
                  }
                >
                  {step.completed ? (
                    <FaCheckCircle className={styles.checkIcon} />
                  ) : (
                    <FaCheckCircle className={styles.uncheckedIcon} />
                  )}
                </button>
                <div
                  className={styles.leftHeader}
                  onClick={() => toggleStepCompletion(step.id)}
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") toggleStepCompletion(step.id);
                  }}
                  aria-label={
                    step.completed
                      ? t(`moverCustomer.steps.${step.key}.toggleIncomplete`, {
                          title: step.title,
                        })
                      : t(`moverCustomer.steps.${step.key}.toggleComplete`, {
                          title: step.title,
                        })
                  }
                >
                  <h3 className={styles.stepTitle}>
                    <span className={styles.icon}>{step.icon}</span>{" "}
                    {step.title}
                  </h3>
                </div>
                <div className={styles.rightHeader}>
                  {/* Expand/Collapse Button */}
                  <button
                    className={styles.toggleButton}
                    onClick={() => toggleExpand(step.id)}
                    aria-label={
                      activeStep === step.id
                        ? t("moverCustomer.buttons.collapse")
                        : t("moverCustomer.buttons.expand")
                    }
                  >
                    {activeStep === step.id ? (
                      <FaChevronUp />
                    ) : (
                      <FaChevronDown />
                    )}
                  </button>
                </div>
              </div>

              {activeStep === step.id && (
                <div className={styles.stepDetails}>
                  {step.completed ? (
                    <div className={styles.completed}>
                      <FaCheckCircle className={styles.checkIcon} />
                      <p>{step.completedText}</p>
                    </div>
                  ) : (
                    <>
                      <p>{step.description}</p>

                      {/* Expandable Section for Step 4 */}
                      {step.id === 5 && (
                        <div className={styles.bookCallSection}>
                          <button
                            className={styles.bookCallToggle}
                            onClick={() => setShowBookCall(!showBookCall)}
                            aria-expanded={showBookCall}
                            aria-controls="book-call"
                          >
                            {showBookCall
                              ? t("moverCustomer.bookCallSection.toggleHide")
                              : t("moverCustomer.bookCallSection.toggleShow")}
                          </button>
                          {showBookCall && (
                            <div
                              id="book-call"
                              className={styles.bookCallInstructions}
                            >
                              <p>
                                {t(
                                  "moverCustomer.bookCallSection.instructions"
                                )}
                              </p>
                            </div>
                          )}
                        </div>
                      )}

                      {/* Expandable Section para el Nuevo Paso */}
                      {step.key === "getToKnowFinances" && (
                        <div className={styles.financesSection}>
                          <p>
                            {t(
                              "moverCustomer.steps.getToKnowFinances.instructions"
                            )}
                          </p>
                        </div>
                      )}

                      {/* Specific Buttons for Certain Steps */}
                      {!([1, 7].includes(step.id)) && (
                        <Link
                          to={
                            step.key === "askQuestions"
                              ? "/chat"
                              : step.key === "accessVideos"
                              ? "/videolibrary"
                              : step.key === "bookCall"
                              ? "/contact-us"
                              : step.key === "getToKnowFinances"
                              ? "/contact-us#finances"
                              : "/my-tasks"
                          }
                          className={`${styles.actionButton} ${styles.prominentButton}`}
                          title={
                            step.key === "askQuestions"
                              ? t("moverCustomer.buttons.goToChat")
                              : step.key === "accessVideos"
                              ? t("moverCustomer.buttons.goToVideoLibrary")
                              : step.key === "bookCall"
                              ? t("moverCustomer.buttons.scheduleCall")
                              : step.key === "getToKnowFinances"
                              ? t("moverCustomer.buttons.scheduleCall")
                              : t("moverCustomer.buttons.goToMyTasks")
                          }
                        >
                          {step.key === "askQuestions" && (
                            <FaQuestionCircle className={styles.actionIcon} />
                          )}
                          {step.key === "accessVideos" && (
                            <FaVideo className={styles.actionIcon} />
                          )}
                          {(step.key === "bookCall" ||
                            step.key === "getToKnowFinances") && (
                            <FaPhoneAlt className={styles.actionIcon} />
                          )}
                          {step.key === "completeTasks" && (
                            <FaCheckCircle className={styles.actionIcon} />
                          )}
                          {step.key === "askQuestions" &&
                            t("moverCustomer.buttons.goToChat")}
                          {step.key === "accessVideos" &&
                            t("moverCustomer.buttons.goToVideoLibrary")}
                          {(step.key === "bookCall" ||
                            step.key === "getToKnowFinances") &&
                            t("moverCustomer.buttons.scheduleCall")}
                          {step.key === "completeTasks" &&
                            t("moverCustomer.buttons.goToMyTasks")}
                        </Link>
                      )}
                      {step.key === "accessVideos" && (
                        <div className={styles.buttonContainer}>
                          <a
                            href="https://open.spotify.com/playlist/33vfAy1R78UjAWagbIofne?si=sTtnjAxATRCGpGan8UlAOg"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`${styles.actionButton} ${styles.prominentButton}`}
                            title={t(
                              "moverCustomer.buttons.openSpotifyPlaylist"
                            )}
                          >
                            <FaSpotify className={styles.actionIcon} />
                            {t("moverCustomer.buttons.openSpotifyPlaylist")}
                          </a>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </section>
      <section className={styles.additionalParagraph}>
        <p>{t("moverCustomer.additionalParagraph")}</p>
      </section>
    </div>
  );
};

export default MoverCustomer;
