import React from "react";
import styles from "../../styles/Tasks styles/Moving.module.css";
import { useTranslation } from "react-i18next";

interface MovingDetailsProps {
  moveType: string;
  from: string;
  to: string;
  approxDate: string;
  isDateUnknown: boolean;
  onFromChange: (value: string) => void;
  onToChange: (value: string) => void;
  onApproxDateChange: (value: string) => void;
  onIsDateUnknownChange: (value: boolean) => void;
  onSubmit: () => void;
  isMultiStep?: boolean;
}

const MovingDetails: React.FC<MovingDetailsProps> = ({
  moveType,
  from,
  to,
  approxDate,
  isDateUnknown,
  onFromChange,
  onToChange,
  onApproxDateChange,
  onIsDateUnknownChange,
}) => {
  const { t } = useTranslation("global");

  const countriesOrigin = [
    { id: "de", name: "moving.from-germany" },
    { id: "au", name: "moving.from-austria" },
    { id: "EU", name: "moving.from-EU" },
    { id: "NonEU", name: "moving.from-out-of-EU" },
  ];

  const countriesDestination = [{ id: "ch", name: "moving.to-switzerland" }];

  return (
    <div className={styles.movingFormContainer}>
      <div className={styles.dropdownContainer}>
        <div className={styles.tofromrow}>
          <div className={styles.dropdown}>
            <label htmlFor="from">{t("moving.from-subtitle")}</label>
            <select
              id="from"
              value={from}
              onChange={(e) => onFromChange(e.target.value)}
              className={styles.selected}
            >
              <option value="">{t("moving.from-selection")}</option>
              {countriesOrigin.map((country) => (
                <option key={country.id} value={country.id}>
                  {t(country.name)}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.dropdown}>
            <label htmlFor="to">{t("moving.to-subtitle")}</label>
            <select
              id="to"
              value={to}
              onChange={(e) => onToChange(e.target.value)}
              className={styles.selected}
            >
              <option value="">{t("moving.to-selection")}</option>
              {countriesDestination.map((country) => (
                <option key={country.id} value={country.id}>
                  {t(country.name)}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className={styles.dateContainer}>
        <label htmlFor="approxDate">{t("moving.date-subtitle")}</label>
        <input
          type="date"
          id="approxDate"
          value={approxDate}
          onChange={(e) => onApproxDateChange(e.target.value)}
          disabled={isDateUnknown}
          className={styles.moving__input}
        />
      </div>

      <div className={styles.checkboxContainer}>
        <input
          type="checkbox"
          id="isDateUnknown"
          checked={isDateUnknown}
          onChange={(e) => {
            onIsDateUnknownChange(e.target.checked);
            if (e.target.checked) {
              onApproxDateChange(" ");
            }
          }}
          className={styles.moving__input}
        />
        <label htmlFor="isDateUnknown" className={styles.checkboxLabel}>
          {t("moving.date-not-clear-selection")}
        </label>
      </div>
    </div>
  );
};

export default MovingDetails;
