// IndividualsContactImage.tsx
import React from "react";

import welcomeImage from "../../assets/contactImage.jpeg";
import styles from "../../styles/Individuals styles/IndividualsContactImage.module.css";
import NavigationButtons from "./NavigationButtons";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faQuestionCircle,
  faPlayCircle,
  faPhone,
  faInfoCircle,
  faGift,
  faList,
  faMap,
  faCalculator,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const IndividualsContactImage = () => {
  const [t, i18n] = useTranslation("global");
  const checklistPDF =
    i18n.language === "de"
      ? "https://drive.google.com/file/d/1Ha_ltWuNNNmthiBXvpEiTJRXdUVVxRRj/view?usp=drive_link"
      : "https://drive.google.com/file/d/1LxTyb-J0AklDGb-Di-FE3B8JfdgiclDd/view";

  return (
    <div className={styles.contactImageContainer}>
      <div className={styles.contactSection}>
        <div>
          <h1>{t("individuals.contact-image-title")}</h1>
          <div className={styles.contentWithBadge}>
            <p>
              {t("individuals.contact-image-paragraph-part1")}{" "}
              <b>{t("individuals.contact-image-paragraph-part1-bold")}</b>{" "}
              {t("individuals.contact-image-paragraph-part2")}{" "}
              <b>{t("individuals.contact-image-paragraph-part2-bold")}</b>{" "}
              {t("individuals.contact-image-paragraph-part3")}
            </p>

            <p>
              <div className={styles.buttonGroupContactImage}>
                <a
                  href="/cost-calculator"
                  className={styles.contactLink}
                  rel="noopener noreferrer"
                >
                  <button className={styles.buttonTextImage}>
                    <FontAwesomeIcon
                      icon={faCalculator}
                      className={styles.icon}
                    />
                    {t("individuals.contact-image-calculator-link")}
                  </button>
                </a>
                <a
                  href="/cost-calculator-map"
                  className={styles.contactLink}
                  rel="noopener noreferrer"
                >
                  <button className={styles.buttonTextImage}>
                    <FontAwesomeIcon icon={faMap} className={styles.icon} />
                    {t("individuals.contact-image-map-link")}
                  </button>
                </a>
                {/*<a
                  href="https://calendly.com/relotu/45min"
                  className={styles.contactLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className={styles.buttonTextImage}>
                    <FontAwesomeIcon icon={faPhone} className={styles.icon} />
                    {t("individuals.contact-image-calendly-link")}
                  </button>
  </a>*/}
                <a
                  href={checklistPDF}
                  className={styles.contactLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className={styles.buttonTextImage}>
                    <FontAwesomeIcon icon={faList} className={styles.icon} />
                    {t("individuals.contact-image-checklist-link")}
                  </button>
                </a>
                <a
                  href="https://api.whatsapp.com/message/YTBSRAKLMEWIE1?autoload=1&app_absent=0"
                  className={styles.contactLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className={styles.buttonTextImage}>
                    <FontAwesomeIcon
                      icon={faWhatsapp}
                      className={styles.icon}
                    />
                    {t("individuals.contact-image-whatsapp-link")}
                  </button>
                </a>
              </div>
            </p>
          </div>
        </div>
      </div>

      <div className={styles.imageSection}>
        <img src={welcomeImage} alt="Welcome Image" />
      </div>
    </div>
  );
};

export default IndividualsContactImage;
