import React from "react";
import { useTranslation } from "react-i18next";

// TASK APARTMENT SEARCH
export const ApartmentSearchDescription: React.FC = () => {
  const { t, i18n } = useTranslation("global");
  console.log("ApartmentSearchDescription renderizado");

  return (
    <div>
      <p>{t("tasks.group1.apartment-search-paragraph1")}</p>

      <ul>
        <li>
          <a href="/videolibrary" target="_blank" rel="noopener noreferrer">
            <strong>
              {t("tasks.group1.apartment-search-list1-item1-bold")}
            </strong>
          </a>{" "}
          {t("tasks.group1.apartment-search-list1-item1-normal")}
        </li>
        <li>
          <strong>{t("tasks.group1.apartment-search-list1-item2-bold")}</strong>{" "}
          {t("tasks.group1.apartment-search-list1-item2-normal")}
        </li>
        <li>
          <strong>{t("tasks.group1.apartment-search-list1-item3-bold")}</strong>{" "}
          <a
            href="https://www.homegate.ch/de"
            target="_blank"
            rel="noopener noreferrer"
          >
            Homegate
          </a>
          ,{" "}
          <a
            href="https://flatfox.ch/c/en/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Flatfox
          </a>
          ,{" "}
          <a
            href="https://www.newhome.ch/de/mieten/suchen/wohnung/ort-zuerich/liste"
            target="_blank"
            rel="noopener noreferrer"
          >
            Newhome
          </a>
        </li>
      </ul>
    </div>
  );
};

// TASK COST OF LIVING
export const CostOfLivingDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <p>{t("tasks.group1.cost-of-living-paragraph1")}</p>

      <ul style={{ listStyleType: "none" }}>
        <li>
          <a href="/cost-calculator" target="_blank" rel="noopener noreferrer">
            {t("tasks.group1.cost-of-living-list1-item1")}
          </a>
        </li>
      </ul>
    </div>
  );
};

// TASK RECOGNITION OF PROF

export const RecognitionProfesionalQualificationsDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.group1.profesional-qualifications-paragraph1")}{" "}
        <a href="/videolibrary" target="_blank" rel="noopener noreferrer">
          <strong>
            {t("tasks.group1.profesional-qualifications-paragraph1-link")}
          </strong>
        </a>{" "}
        {t("tasks.group1.profesional-qualifications-paragraph2")}
      </p>

      <ul>
        <li>
          <a href="/videolibrary" target="_blank" rel="noopener noreferrer">
            <strong>
              {t("tasks.group1.profesional-qualifications-list1-item1-bold")}
            </strong>
          </a>{" "}
          {t("tasks.group1.profesional-qualifications-list1-item1")}
        </li>
        <li>
          <strong>
            {" "}
            {t("tasks.group1.profesional-qualifications-list1-item2-bold")}{" "}
          </strong>
          <a
            href="https://www.edk.ch/de/themen/diplomanerkennung"
            target="_blank"
            rel="noopener noreferrer"
          >
            Anerkennung.Swiss{" "}
          </a>
        </li>
      </ul>
    </div>
  );
};

export const ReviewInsuranceDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.group1.review-insurance-paragraph1")}
        <br />
        <a href="/contact-us" target="_blank" rel="noopener noreferrer">
          {t("tasks.group1.review-insurance-bold2-link")}
        </a>{" "}
        {t("tasks.group1.review-insurance-paragraph2")}
      </p>
    </div>
  );
};

export const CancelContractsDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group1.cancel-contracts-paragraph1")}</p>

      <ul>
        <li>
          <strong>
            {t("tasks.group1.cancel-contracts-list1-item1-bold")}{" "}
          </strong>
          {t("tasks.group1.cancel-contracts-list1-item1")}
        </li>
        <li>
          <strong>
            {t("tasks.group1.cancel-contracts-list1-item2-bold")}{" "}
          </strong>
          {t("tasks.group1.cancel-contracts-list1-item2")}
        </li>
      </ul>
    </div>
  );
};

export const BankInvestmentDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.group1.bank-investment-paragraph1")}{" "}
        <a href="/contact-us" target="_blank" rel="noopener noreferrer">
          {t("tasks.group1.bank-investment-paragraph2-link")}
        </a>{" "}
        {t("tasks.group1.bank-investment-paragraph2")}
      </p>
    </div>
  );
};

export const RegisterHouseholdDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group1.register-household-paragraph1")}</p>

      <ul>
        <li>
          <a href="/videolibrary" target="_blank" rel="noopener noreferrer">
            <strong>{t("tasks.group1.register-household-list1-item1-link")}</strong>
          </a>{" "}
          {t("tasks.group1.register-household-list1-item1")}
        </li>
        <li>
          <a href="https://www.bazg.admin.ch/dam/bazg/de/dokumente/verfahren-betrieb/Aufgabenvollzug/Formulare/18_44_uebersiedlungsgut.pdf.download.pdf/18_44_uebersiedlungsgut.pdf" target="_blank" rel="noopener noreferrer">
            <strong>{t("tasks.group1.register-household-list1-item2-link")}</strong>
          </a>{" "}
          {t("tasks.group1.register-household-list1-item2")}
        </li>
      </ul>
    </div>
  );
};

export const PetsWithYouDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group1.pets-paragraph1")}</p>

      <ul>
        <li>
          <a href="/videolibrary" target="_blank" rel="noopener noreferrer">
            <strong>{t("tasks.group1.pets-list1-item1-bold")}</strong>
          </a>{" "}
          {t("tasks.group1.pets-list1-item1")}
        </li>
      </ul>
    </div>
  );
};

export const ImportYourCarDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group1.car-paragraph1")}</p>
    </div>
  );
};

export const DeregistrationDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.group1.deregistration-paragraph1")}
        <br />
        <br />
        <strong>{t("tasks.group1.deregistration-paragraph2-bold")}</strong>
        {t("tasks.group1.deregistration-paragraph2")}
      </p>
    </div>
  );
};

export const PostalMailDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.group1.postal-mail-paragraph1")}

        <ul style={{ listStyleType: "none" }}>
          <li>
            <a
              href="https://www.deutschepost.de/de/n/nachsendeservice.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("tasks.group1.postal-mail-list1-item1")}
            </a>
          </li>
          <li>
            <a
              href="https://www.post.at/p/a/nachsenden"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("tasks.group1.postal-mail-list1-item2")}
            </a>
          </li>
        </ul>
      </p>
    </div>
  );
};

export const FarewellDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group1.farewell-paragraph1")}</p>
    </div>
  );
};

// TASK SWISS GERMAN
export const SwissGermanDescription: React.FC = () => {
  const { t, i18n } = useTranslation("global");

  return (
    <div>
      <p>{t("tasks.group1.swiss-german-paragraph1")}</p>

      <ul>
        <li>
          <strong>{t("tasks.group1.swiss-german-list1-item1-bold")} </strong>
          <a
            href="https://open.spotify.com/playlist/33vfAy1R78UjAWagbIofne?si=sTtnjAxATRCGpGan8UlAOg"
            target="_blank"
            rel="noopener noreferrer"
          >
            Spotify Playlist
          </a>
        </li>
        <li>
          <strong>{t("tasks.group1.swiss-german-list1-item1-bold")} </strong>
          <a
            href="https://open.spotify.com/playlist/77RqUhWolAoWyXBAO2pcGH?si=YoZFqJCjTpSTHj1Lj4te1w"
            target="_blank"
            rel="noopener noreferrer"
          >
            Spotify Playlist
          </a>
        </li>
      </ul>
    </div>
  );
};
