// events/pixelEvents.ts
declare global {
    interface Window {
      fbq: (...args: any[]) => void;
    }
  }
  
  /**
   * Tracks a Facebook Pixel event.
   * @param eventName - Name of the event (e.g., "PageView", "CompleteRegistration").
   * @param params - Additional parameters for the event.
   */
  export const trackPixelEvent = (eventName: string, params: Record<string, any> = {}) => {
    if (typeof window !== "undefined" && window.fbq) {
      window.fbq("track", eventName, params);
      console.log(`Pixel Event Triggered: ${eventName}`, params);
    } else {
      console.warn("Facebook Pixel is not initialized.");
    }
  };
  
  
/**
 * Tracks a visit to the Base Page.
 */
export const trackMainPageVisit = () => {
    trackPixelEvent("PageView", { page: "MainPage" });
  };
  
  /**
   * Tracks a visit to the Signup Page.
   */
  export const trackSignupPageVisit = () => {
    trackPixelEvent("ViewContent", { page: "SignupPage" });
  };
  
  /**
   * Tracks a successful registration.
   */
  export const trackRegistrationSuccess = () => {
    trackPixelEvent("CompleteRegistration", { page: "SuccessPage" });
  };
  