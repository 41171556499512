import React, { useState } from "react";
import styles from "../../styles/Calculator public styles/CostCalculatorMain.module.css";
import CostCalculatorGross from "./CostCalculatorGross";
import CostCalculatorNet from "./CostCalculatorNet";
import { useTranslation } from "react-i18next";

const CostCalculatorMain: React.FC = () => {
  const { t } = useTranslation("global");

  const [activeTab, setActiveTab] = useState<"gross" | "net">("gross");

  const [calculationResults, setCalculationResults] = useState<{
    netIncome: number | null;
    familyMembers: number;
    apartmentCost: number | null;
    tax: number | null;
    healthInsuranceCost: number | null;
  }>({
    netIncome: null,
    familyMembers: 0,
    apartmentCost: null,
    tax: null,
    healthInsuranceCost: null,
  });

  const handleCalculateResults = (results: {
    netIncome: number | null;
    familyMembers: number;
    apartmentCost: number | null;
    tax: number | null;
    healthInsuranceCost: number | null;
  }) => {
    setCalculationResults(results);
    console.log("Resultados del cálculo:", results);
  };

  const goToNextTab = () => {
    setActiveTab("net");
  };

  const [selectedLocation, setSelectedLocation] = useState<{
    canton: string;
    community: string;
  }>({
    canton: "",
    community: "",
  });

  const handleLocationChange = (canton: string, community: string) => {
    setSelectedLocation({ canton, community });
  };

  return (
    <div className={styles.costCalculatorMain__tabsContainer}>
      <h2>{t("calculator.main.title")}</h2>
      <div className={styles.costCalculatorMain__tabHeaders}>
        <button
          className={`${styles.costCalculatorMain__tabButton} ${
            activeTab === "gross" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("gross")}
        >
          {t("calculator.main.input")}
        </button>
        <button
          className={`${styles.costCalculatorMain__tabButton} ${
            activeTab === "net" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("net")}
        >
          {t("calculator.main.results")}
        </button>
      </div>

      <div className={styles.costCalculatorMain__tabContent}>
        {activeTab === "gross" ? (
          <CostCalculatorGross
            onCalculateResults={handleCalculateResults}
            onNextTab={goToNextTab}
            onLocationChange={handleLocationChange}
          />
        ) : (
          <CostCalculatorNet
            netIncome={calculationResults.netIncome ?? 0}
            familyMembers={calculationResults.familyMembers}
            apartmentCost={calculationResults.apartmentCost ?? 0}
            healthInsuranceCost={calculationResults.healthInsuranceCost ?? 0}
            canton={selectedLocation.canton}
            community={selectedLocation.community}
          />
        )}
      </div>
    </div>
  );
};

export default CostCalculatorMain;
