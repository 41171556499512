declare global {
  interface Window {
    gtag?: (...args: any[]) => void;
  }
}

export const trackConversionEvent = (
  eventName: string,
  eventParams: Record<string, any> = {}
) => {
  console.log(
    `trackConversionEvent called with eventName: ${eventName}`,
    eventParams
  );

  if (typeof window.gtag === "function") {
    console.log("Gtag function is available, sending event...");
    window.gtag("event", eventName, eventParams);
    console.log("Event sent:", { eventName, eventParams });
  } else {
    console.warn("Gtag is not initialized.");
  }
};

