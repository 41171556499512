import React, { useEffect, useState } from "react";

import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieLabelRenderProps,
} from "recharts";
import styles from "../../styles/Calculator public styles/CostCalculatorNet.module.css";
import { useTranslation } from "react-i18next";

const COLORS = [
  "#428c9f",
  "#2a6f86",
  "#a3d1dc",
  "#8c5b94",
  "#ffaf40",
  "#61a9b3",
  "#ffd24c",
];

interface CostCalculatorResultsProps {
  isCalculated: boolean;
  canton: string;
  community: string;
  totalExpenses: number;
  balance: number;
  income: number;
  disposableIncomePercentage: number;
  data: any[];
  renderLabel: (props: PieLabelRenderProps) => JSX.Element; // Change this line
  CustomTooltip: React.ComponentType;
  windowWidth: number;
}

// Función para formatear números con separadores de miles
const formatNumber = (num: number) => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(num);
};

const CostCalculatorResults: React.FC<CostCalculatorResultsProps> = ({
  isCalculated,
  totalExpenses,
  balance,
  income,
  disposableIncomePercentage,
  data,
  renderLabel,
  CustomTooltip,
  windowWidth,
  canton,
  community,
}) => {
  const { t } = useTranslation("global");

  const [selectedCanton, setSelectedCanton] = useState<string | null>(null);

  useEffect(() => {
    const fetchCantons = async () => {
      try {
        const response = await fetch("https://api.relotu.com/datasets/cantons/");
        const cantons = await response.json();
        console.log("Cantons data received:", cantons); // Verificar datos recibidos

        // Buscar el canton por ID
        const foundCanton = cantons.find(
          (cantonItem: { cantonId: string; cantonName: string }) => cantonItem.cantonId === canton
        );

        if (foundCanton) {
          setSelectedCanton(foundCanton.cantonName); // Guardar el nombre del canton encontrado
        } else {
          console.log("Canton not found for ID:", canton);
        }
      } catch (error) {
        console.log("Error fetching cantons:", error);
      }
    };

    fetchCantons();
  }, [canton]);

  return (
    <div className={styles.costCalculatorNet__results}>
      <h3>{t("calculator.net.resultsTitle")}</h3>
      {isCalculated ? (
        <>
          <p>{t("calculator.net.resultsParagraph")}</p>
          <div className={styles.costCalculatorNet__summary}>
  <div className={styles.costCalculatorNet__row}>
    <p>
      <strong>{t("calculator.net.cantonLabel")}</strong> {selectedCanton}
    </p>
    <p>
      <strong>{t("calculator.net.communityLabel")}</strong> {community}
    </p>
  </div>
  <div className={styles.costCalculatorNet__row}>
    <p>
      <strong>{t("calculator.net.totalCost")}</strong> CHF{" "}
      {formatNumber(Number(totalExpenses.toFixed(2)))}
    </p>
    <p>
      <strong>{t("calculator.net.remainingBalance")}</strong> CHF{" "}
      {formatNumber(Number(balance.toFixed(2)))}
    </p>
  </div>
</div>


          {balance < 0 && income > 0 && (
            <p className={styles.costCalculatorNet__warning}>
              {t("calculator.net.warning")}
            </p>
          )}
          {balance > 0 && (
            <div className={styles.costCalculatorNet__progressBarContainer}>
              <label>{t("calculator.net.disposableIncomePercentage")}</label>
              <div className={styles.costCalculatorNet__progressBar}>
                <div
                  className={styles.costCalculatorNet__progress}
                  style={{
                    width: `${Math.min(
                      Math.max(disposableIncomePercentage, 0),
                      100
                    )}%`,
                  }}
                ></div>
              </div>
              <p>{disposableIncomePercentage.toFixed(2)}%</p>
            </div>
          )}

          {totalExpenses > 0 && (
            <div className={styles.costCalculatorNet__chartContainer}>
              <h4>{t("calculator.net.expenseDistribution")}</h4>
              <ResponsiveContainer
                width="100%"
                height={windowWidth < 768 ? 200 : 500}
              >
                <PieChart margin={{ top: 20, right: 20, bottom: 20, left: 30 }}>
                  <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={true} // Muestra líneas de conexión
                    label={renderLabel} // Función personalizada para renderizar labels
                    outerRadius={windowWidth < 768 ? "50%" : "70%"}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip content={<CustomTooltip />} />
                  <Legend
                    layout={windowWidth < 600 ? "vertical" : "horizontal"}
                    align={windowWidth < 600 ? "right" : "center"}
                    verticalAlign={windowWidth < 600 ? "middle" : "bottom"}
                    wrapperStyle={{
                      fontSize: windowWidth < 600 ? "9px" : "16px",
                      right: "-33px",
                    }}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          )}
        </>
      ) : (
        <p>{t("calculator.net.prompt")}</p>
      )}
    </div>
  );
};

export default CostCalculatorResults;
