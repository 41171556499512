// TasksIndividuals.tsx
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/Tasks styles/MovingCommutingDetails.module.css";
import tasksImage from "../../assets/toDos.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faSquare,
  faToggleOff,
  faToggleOn,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import ProgressBar from "../ProgressBar";
import { groupNames, componentMap } from "./TasksData";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context/AuthContext";

// Define the interface for a task
interface Task {
  id: string; // Maps to _id from the backend
  title: string;
  description: JSX.Element;
  isCompleted: boolean; // Changed from 'completed' to 'isCompleted'
  category: string;
  type: string;
  group: number;
}

const TasksIndividuals: React.FC = () => {
  const { t } = useTranslation("global");
  const { token } = useContext(AuthContext); // Access the authentication token
  const [tasks, setTasks] = useState<Task[]>([]);
  const [expandedGroup, setExpandedGroup] = useState<number | null>(null);
  const [expandedTask, setExpandedTask] = useState<string | null>(null);
  const [showCompletedGroups, setShowCompletedGroups] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {

    const fetchTasks = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(
          "https://api.relotu.com/individuals/user-tasks",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-relotu-access": token!, // Non-null assertion
            },
          }
        );


        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Error fetching tasks");
        }

        const data: any[] = await response.json(); // Backend returns an array directly

        // Transform backend data to the format expected by the frontend
        const transformedTasks: Task[] = data.map((backendTask) => {
          const DescriptionComponent = componentMap[backendTask.component];
          if (!DescriptionComponent) {
            console.warn(
              `Component "${backendTask.component}" not found in the mapping.`
            );
          } else {
           
          }

          return {
            id: backendTask._id, // Always use '_id'
            title: backendTask.title,
            description: DescriptionComponent ? (
              <DescriptionComponent />
            ) : (
              <div>{t("tasks.no-description")}</div>
            ),
            isCompleted: backendTask.isCompleted, // Use 'isCompleted' directly
            group: parseInt(backendTask.group, 10),
            category: backendTask.category,
            type: backendTask.type,
          };
        });

        setTasks(transformedTasks);
      } catch (err: any) {
        console.error("Error fetching tasks:", err);
        setError(t("tasks.error-fetching-tasks"));
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      fetchTasks();
    } else {
      setError(t("tasks.not-authenticated"));
    }
  }, [token, t]);

  const toggleTaskCompleted = async (id: string) => {
    try {
      // Find the task to update
      const taskToUpdate = tasks.find((task) => task.id === id);
      if (!taskToUpdate) {
        throw new Error("Task not found");
      }

      // Toggle the isCompleted state
      const newIsCompleted = !taskToUpdate.isCompleted;

      // Update the state locally
      const updatedTasks = tasks.map((task) =>
        task.id === id ? { ...task, isCompleted: newIsCompleted } : task
      );
      setTasks(updatedTasks);

      // Make the PUT request to the backend
      const response = await fetch(`https://api.relotu.com/user-tasks/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-relotu-access": token!,
        },
        body: JSON.stringify({
          isCompleted: newIsCompleted, // Use "isCompleted"
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Error updating task");
      }

    } catch (err: any) {
      console.error("Error updating task:", err);
      setError(t("tasks.error-updating-task"));
      // Revert the change in the frontend if the update fails
      const revertedTasks = tasks.map((task) =>
        task.id === id ? { ...task, isCompleted: !task.isCompleted } : task
      );
      setTasks(revertedTasks);
    }
  };

  const toggleExpandGroup = (group: number) => {
    setExpandedGroup((prevGroup) => (prevGroup === group ? null : group));
    setExpandedTask(null);
  };

  const toggleExpandTask = (id: string) => {
    setExpandedTask((prevTask) => (prevTask === id ? null : id));
  };


  const isGroupEmpty = (groupIndex: number) => {
    return tasks.filter((task) => task.group === groupIndex + 1).length === 0;
  };

  const isGroupCompleted = (group: number) => {
    const groupTasks = tasks.filter(
      (selectedTask) => selectedTask.group === group
    );
    return (
      groupTasks.length > 0 &&
      groupTasks.every((selectedTask) => selectedTask.isCompleted)
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img src={tasksImage} alt="Tasks" className={styles.tasksImage} />
      </div>
      <div className={styles.content}>
        <h1 className={styles.title}>{t("moving-details.title")}</h1>
        <p className={styles.subtitle}>{t("moving-details.paragraph-title")}</p>

        <div className={styles.toggleButtonsContainer}>
          <button
            className={`${styles.toggleButton} ${
              showCompletedGroups ? styles.toggleOn : styles.toggleOff
            }`}
            onClick={() => setShowCompletedGroups((prev) => !prev)}
          >
            <FontAwesomeIcon
              icon={showCompletedGroups ? faToggleOn : faToggleOff}
              className={styles.toggleIcon}
            />
            {showCompletedGroups
              ? t("moving-details.toggle-hide-groups")
              : t("moving-details.toggle-show-groups")}
          </button>
        </div>

        <ProgressBar
          totalTasks={tasks.length}
          completedTasks={tasks.filter((task) => task.isCompleted).length} // Changed from 'completed' to 'isCompleted'
        />

        {isLoading && <p>{t("tasks.loading")}</p>}
        {error && <p className={styles.error}>{error}</p>}

        <div className={styles.tasksList}>
          {groupNames.map((groupName, groupIndex) => {
            const groupNumber = groupIndex + 1;
            const isCurrentGroupCompleted = isGroupCompleted(groupNumber);
            if (!showCompletedGroups && isCurrentGroupCompleted) {
              return null;
            }
            if (isGroupEmpty(groupIndex)) {
              return null;
            }

            const groupTasks = tasks.filter(
              (task) => task.group === groupNumber
            );


            return (
              <div key={groupIndex} className={styles.groupContainer}>
                <div
                  className={styles.groupHeader}
                  onClick={() => toggleExpandGroup(groupNumber)}
                >
                  <span>{t(groupName)}</span>
                  <FontAwesomeIcon
                    icon={
                      isGroupCompleted(groupNumber) ? faCheckSquare : faSquare
                    }
                    className={`${styles.groupIcon} ${
                      isGroupCompleted(groupNumber) && styles.checked
                    }`}
                  />
                </div>
                {expandedGroup === groupNumber && (
                  <div className={styles.groupTasks}>
                    {groupTasks.map((task) => (
                      <div key={task.id} className={styles.taskItem}>
                        <div
                          className={styles.taskHeader}
                          onClick={() => toggleExpandTask(task.id)}
                        >
                          <span className={styles.taskTitle}>
                            {t(task.title)}
                          </span>
                          <input
                            type="checkbox"
                            checked={task.isCompleted} // Changed from 'completed' to 'isCompleted'
                            onChange={() => toggleTaskCompleted(task.id)}
                            onClick={(e) => e.stopPropagation()}
                            className={styles.checkbox}
                          />
                        </div>

                        {expandedTask === task.id && (
                          // to be displayed based on permissions
                          <div className={styles.taskDescription}>
                            {task.description}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        {/*<div className={styles.containerDetailsButton}>
          <Link to="/contact-us">
            <button className={styles.actionButton}>
              <FontAwesomeIcon icon={faPhone} className={styles.iconPhone} />
              {t("moving-details.call-button")}
            </button>
          </Link>
        </div>*/}
      </div>
    </div>
  );
};

export default TasksIndividuals;
