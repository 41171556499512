// IndividualsTextImage.tsx
import React from "react";

import imagePrototypeEN from "../../assets/imagePrototypeEN.png";
import imagePrototypeDE from "../../assets/imagePrototypeDE.png";
import styles from "../../styles/Individuals styles/IndividualsTextImage.module.css";
import NavigationButtons from "./NavigationButtons";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faQuestionCircle,
  faPlayCircle,
  faPhone,
  faInfoCircle,
  faGift,
  faGlobeEurope,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";

const IndividualsTextImage = () => {
  const [t, i18n] = useTranslation("global");
  const imagePrototype = i18n.language ==="de"
  ? imagePrototypeDE : imagePrototypeEN

  return (
    <div className={styles.textImageContainer}>
      <div className={styles.imageSection}>
        <img src={imagePrototype} alt="Welcome Image" />
      </div>
      <div className={styles.textSection}>
        <div>
          <h1>{t("individuals.text-image-title")}</h1>
          <div className={styles.contentWithBadge}>
            <p>
              <ul className={styles.iconList}>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className={styles.icon}
                  />
                  {t("individuals.text-image-list1-item1")}
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className={styles.icon}
                  />
                  {t("individuals.text-image-list1-item2")}
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faGlobeEurope}
                    className={styles.icon}
                  />
                  {t("individuals.text-image-list1-item3")}
                </li>
                <li>
                  <FontAwesomeIcon icon={faPlayCircle} className={styles.icon} />
                  {t("individuals.text-image-list1-item4")}
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faHeadset}
                    className={styles.icon}
                  />
                  {t("individuals.text-image-list1-item5")}
                </li>
              </ul>
            </p>
          </div>
          <div className={styles.buttonTextImageSection}>
            {/* Contenedor de botones */}
            {/* Botón de Login */}
            <a href="/login-individuals">
              <button className={styles.buttonTextImage}>
                {t("navbar.login-button")}
              </button>
            </a>
            {/* Botón de Sign Up */}
            <a href="/registration-individuals">
              <button
                className={`${styles.buttonTextImage} ${styles.registerButton}`}
              >
                {t("navbar.register-button")}
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualsTextImage;
