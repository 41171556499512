import styles from "../../styles/Interactive Map styles/InteractiveMapCloropleth.module.css"; // Cambia el nombre del archivo si lo necesitas

import React, { useState, useEffect } from "react";
import Select from "react-select";
import chSvg from "../../assets/ch.svg";
import { useTranslation } from "react-i18next";

interface Canton {
  cantonId: string;
  cantonName: string;
  taxes: number;
  apartmentPrice: number;
  socialScores: number;
}

interface Canton {
  cantonId: string;
  cantonName: string;
  general: any;
  financeAndCareer: any;
  apartments: any;
  educationAndHealth: any;
  mobility: any;
  socialScore: any;
}

const ChoroplethMap: React.FC = () => {
  const { t } = useTranslation("global");

  const [cantons, setCantons] = useState<Canton[]>([]);
  const [svgDoc, setSvgDoc] = useState<Document | null>(null);
  const [selectedCantonId, setSelectedCantonId] = useState<string | null>(null);
  const [hoveredCantonId, setHoveredCantonId] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<string>("general");
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [cantonDetails, setCantonDetails] = useState<Canton | null>(null);

  useEffect(() => {
    const fetchCantons = async () => {
      try {
        const response = await fetch("https://api.relotu.com/datasets/cantons");
        //const response = await fetch("http://localhost:8080/datasets/cantons");
        const data = await response.json();
        setCantons(data); // Guarda los cantones en el estado
      } catch (error) {
        console.error("Error fetching cantons:", error);
      }
    };

    fetchCantons();
  }, []);

  useEffect(() => {
    const fetchCantonDetails = async () => {
      if (!selectedCantonId) return;

      try {
        const response = await fetch(
          `https://api.relotu.com/datasets/cantons/${selectedCantonId}`
          //`http://localhost:8080/datasets/cantons/${selectedCantonId}`
        );
        const data = await response.json();

        console.log("Fetched canton details:", data);

        // If the data is an array, select the first element
        if (Array.isArray(data) && data.length > 0) {
          setCantonDetails(data[0]);
        } else if (typeof data === "object" && data !== null) {
          setCantonDetails(data);
        } else {
          console.error("Unexpected data format:", data);
          setCantonDetails(null);
        }
      } catch (error) {
        console.error("Error fetching canton details:", error);
      }
    };

    fetchCantonDetails();
  }, [selectedCantonId]);

  const cantonOptions = cantons.map((canton) => ({
    value: canton.cantonId,
    label: canton.cantonName,
  }));

  const handleSvgLoad = (event: React.SyntheticEvent) => {
    const doc = (event.target as HTMLObjectElement).contentDocument;
    if (doc) {
      setSvgDoc(doc);
      const paths = doc.querySelectorAll("path, polygon");
      paths.forEach((path) => {
        const id = path.getAttribute("id");
        if (id) {
          path.addEventListener("mouseenter", (e) => {
            setHoveredCantonId(id);
            const rect = (e.target as SVGElement).getBoundingClientRect();
            setTooltipPosition({ x: rect.left + rect.width / 2, y: rect.top });
          });
          path.addEventListener("mouseleave", () => setHoveredCantonId(null));
          path.addEventListener("click", () => setSelectedCantonId(id));
        }
      });
    }
  };

  const updateMapStyles = () => {
    if (svgDoc) {
      const paths = svgDoc.querySelectorAll("path, polygon");
      paths.forEach((path) => {
        const id = path.getAttribute("id");
        const canton = cantons.find((c) => c.cantonId === id);

        path.removeAttribute("style"); // Limpia estilos anteriores

        if (activeTab === "general") {
          // Para la pestaña "General", estilo base uniforme
          path.setAttribute(
            "style",
            "fill: #ff; stroke: #000; stroke-width: 0.5px;"
          );

          // Efecto hover
          if (id === hoveredCantonId) {
            path.setAttribute(
              "style",
              `fill: #428c9f; stroke: #000; stroke-width: 1px;`
            );
          }

          // Efecto selected
          if (id === selectedCantonId) {
            path.setAttribute(
              "style",
              `fill: #fff; stroke: #000; stroke-width: 1.5px;`
            );
          }

          return; // Salir para la pestaña "General"
        }

        // Configuración de otras pestañas
        if (canton) {
          const value =
            activeTab === "financeAndCareer"
              ? canton.financeAndCareer
              : activeTab === "apartmentPrice"
              ? canton.apartmentPrice
              : canton.socialScore;

          const maxValue =
            activeTab === "financeAndCareer"
              ? 25
              : activeTab === "apartmentPrice"
              ? 15000
              : 100;

          const intensity = Math.min(value / maxValue, 1);
          const baseColor = { r: 66, g: 140, b: 159 }; // Color base: #428c9f
          const maxLightness = 240; // Azul pálido muy claro

          const color = `rgb(
            ${baseColor.r + (maxLightness - baseColor.r) * (1 - intensity)},
            ${baseColor.g + (maxLightness - baseColor.g) * (1 - intensity)},
            ${baseColor.b + (maxLightness - baseColor.b) * (1 - intensity)}
          )`;

          path.setAttribute(
            "style",
            `fill: ${color}; stroke: #000; stroke-width: 0.5px;`
          );

          if (id === selectedCantonId) {
            path.setAttribute(
              "style",
              `fill: #fff; stroke: #000; stroke-width: 1.5px;`
            );
          }

          if (id === hoveredCantonId) {
            path.setAttribute(
              "style",
              `fill: #fff; stroke: #000; stroke-width: 1px;`
            );
          }
        }
      });
    }
  };

  useEffect(() => {
    updateMapStyles();
  }, [activeTab, selectedCantonId, hoveredCantonId, svgDoc]);

  return (
    <div className={styles.interactiveMapCloropleth__container}>
      <h2>{t("calculator.map.title")}</h2>
      <p>{t("calculator.map.paragraph1")}</p>
      <br />
      <p>
        {t("calculator.map.paragraph2-1")}{" "}
        <a
          href="/registration-individuals"
          target="_blank"
          style={{ color: "#428c9f", textDecoration: "underline" }}
        >
          {t("calculator.map.paragraph2-link1")}
        </a>{" "}
        {t("calculator.map.paragraph2-2")}{" "}
        <a
          href="/contact-us"
          target="_blank"
          style={{ color: "#428c9f", textDecoration: "underline" }}
        >
          {t("calculator.map.paragraph2-link2")}
        </a>{" "}
        {t("calculator.map.paragraph2-3")}
      </p>

      <div className={styles.interactiveMapCloropleth__content}>
        <div className={styles.interactiveMapCloropleth__infoBox}>
          {selectedCantonId ? (
            <div>
              <h3>
                {cantons.find((c) => c.cantonId === selectedCantonId)
                  ?.cantonName || "Canton"}
              </h3>
              <p>
                {activeTab === "general" && (
                  <>
                    <ul className={styles.infoBox__list}>
                      <li className={styles.infoBox__listItem}>
                        <b>{t("calculator.map.population")} </b>
                        {cantonDetails?.general.population.toLocaleString()}
                      </li>
                      <li className={styles.infoBox__listItem}>
                        <b>{t("calculator.map.languageSpoken")}</b>
                        {cantonDetails?.general.languageSpoken}
                      </li>
                      <li className={styles.infoBox__listItem}>
                        <b>{t("calculator.map.mainCities")} </b>
                        {cantonDetails?.general.mainCities}
                      </li>
                      <li className={styles.infoBox__listItem}>
                        <b>{t("calculator.map.famousFor")} </b>
                        {cantonDetails?.general.famousFor}
                      </li>
                      <li className={styles.infoBox__listItem}>
                        <b>{t("calculator.map.funFact")}</b>{" "}
                        {cantonDetails?.general.funFact}
                      </li>
                    </ul>
                  </>
                )}
                {activeTab === "financeAndCareer" && (
                  <>
                    <ul>
                      <li>
                        <b>{t("calculator.map.mainIndustries")} </b>
                        {cantonDetails?.financeAndCareer.mainIndustries}
                      </li>
                      <li>
                        <b>{t("calculator.map.medianIncome")} </b>CHF
                        {cantonDetails?.financeAndCareer.medianIncome.toLocaleString()}
                      </li>
                      <li>
                        <b>{t("calculator.map.disposableIncome")} </b>CHF
                        {cantonDetails?.financeAndCareer.disposableIncome.toLocaleString()}
                      </li>
                      <li>
                        <b>{t("calculator.map.taxAtSource")} </b>
                        {cantonDetails?.financeAndCareer.taxAtSourceRate}%
                      </li>
                      <li>
                        <b>{t("calculator.map.taxRate")} </b>
                        {cantonDetails?.financeAndCareer.taxRate}%
                      </li>
                      <li>
                        <b>{t("calculator.map.taxFreeAllowance")} </b>CHF
                        {cantonDetails?.financeAndCareer.taxFreeAllowanceForWealthTax.toLocaleString()}
                      </li>
                    </ul>
                  </>
                )}
                {activeTab === "apartments" && (
                  <>
                    <ul>
                      <li>
                        <b>{t("calculator.map.averageRentalPrice")} </b>CHF
                        {cantonDetails?.apartments.averageRentalPrice.toLocaleString()}
                      </li>
                      <li>
                        <b>{t("calculator.map.rentalPriceM2Rent")} </b>CHF
                        {cantonDetails?.apartments.averageRentalPriceM2Rent.toLocaleString()}
                      </li>
                      <li>
                        <b>{t("calculator.map.rentalPriceM2Buy")} </b>CHF
                        {cantonDetails?.apartments.averageRentalPriceM2Buy.toLocaleString()}
                      </li>
                      <li>
                        <b>{t("calculator.map.vacantHousigRate")} </b>
                        {cantonDetails?.apartments.vacantHousingRate}%
                      </li>
                    </ul>
                  </>
                )}
                {activeTab === "educationAndHealth" && (
                  <>
                    <ul>
                      <li>
                        <b>{t("calculator.map.childrenGymnasium")} </b>
                        {
                          cantonDetails?.educationAndHealth
                            .percentageOfChildrenGoingToGymnasium
                        }
                        %
                      </li>
                      <li>
                        <b>{t("calculator.map.universities")} </b>
                        {cantonDetails?.educationAndHealth.numberOfUniversities}
                      </li>
                      <li>
                        <b>{t("calculator.map.famousFields")} </b>
                        {cantonDetails?.educationAndHealth.famousFieldsOfStudy}
                      </li>
                      <li>
                        <b>{t("calculator.map.largestHospital")} </b>
                        {cantonDetails?.educationAndHealth.largestHospital}
                      </li>
                    </ul>
                  </>
                )}
                {activeTab === "mobility" && (
                  <>
                    <ul>
                      <li>
                        <b>{t("calculator.map.trainHub")} </b>
                        {cantonDetails?.mobility.mainTrainHub}
                      </li>
                      <li>
                        <b>{t("calculator.map.timeToMountains")} </b>
                        {cantonDetails?.mobility.timeToMountainsByTrain}
                      </li>
                      <li>
                        <b>{t("calculator.map.timeToGermany")} </b>
                        {cantonDetails?.mobility.timeToGermanyByTrain}
                      </li>
                      <li>
                        <b>{t("calculator.map.timeToAustria")} </b>
                        {cantonDetails?.mobility.timeToAustriaByTrain}
                      </li>
                      <li>
                        <b>{t("calculator.map.timeToItaly")} </b>
                        {cantonDetails?.mobility.timeToItalyByTrain}
                      </li>
                      <li>
                        <b>{t("calculator.map.timeToFrance")} </b>
                        {cantonDetails?.mobility.timeToFranceByTrain}
                      </li>
                    </ul>
                  </>
                )}
                {activeTab === "socialScore" && (
                  <>
                    <ul>
                      <li>
                        <b>{t("calculator.map.overallSocialScore")}</b>{" "}
                        {cantonDetails?.socialScore.score}
                      </li>
                      <li>
                        <b>{t("calculator.map.expatPopulation")}</b>{" "}
                        {cantonDetails?.socialScore.expatPopulation}
                      </li>
                      <li>
                        <b>{t("calculator.map.museums")}</b>{" "}
                        {cantonDetails?.socialScore.museums}
                      </li>
                      <li>
                        <b>{t("calculator.map.restaurants")}</b>{" "}
                        {cantonDetails?.socialScore.restaurants.toLocaleString()}
                      </li>
                      <li>
                        <b>{t("calculator.map.funFact")}</b>{" "}
                        {cantonDetails?.socialScore.events}
                      </li>
                    </ul>
                  </>
                )}
              </p>
            </div>
          ) : (
            <p>{t("calculator.map.paragraphInfoSelect")}</p>
          )}

          <div className={styles.interactiveMapCloropleth__description}>
            <p>
              {activeTab === "general" && ""}
              {activeTab === "financeAndCareer" &&
                t("calculator.map.noteUnmarriedSinglePerson")}
              {activeTab === "apartments" &&
                t("calculator.map.noteApproxNumbers")}{" "}
              {activeTab === "educationAndHealth" && ""}
              {activeTab === "mobility" && ""}
              {activeTab === "socialScore" && ""}
            </p>
          </div>
        </div>

        <div className={styles.interactiveMapCloropleth__mapBox}>
          <Select
            options={cantonOptions}
            onChange={(selectedOption) =>
              setSelectedCantonId(selectedOption ? selectedOption.value : null)
            }
            value={cantonOptions.find(
              (option) => option.value === selectedCantonId
            )}
            placeholder="Select Canton..."
            isClearable
          />

          <div className={styles.interactiveMapCloropleth__tabs}>
            <button
              onClick={() => setActiveTab("general")}
              className={
                activeTab === "general"
                  ? styles.interactiveMapCloropleth__activeTab
                  : ""
              }
            >
              {t("calculator.map.general")}
            </button>

            <button
              onClick={() => setActiveTab("financeAndCareer")}
              className={
                activeTab === "financeAndCareer"
                  ? styles.interactiveMapCloropleth__activeTab
                  : ""
              }
            >
              {t("calculator.map.financeAndCareer")}
            </button>
            <button
              onClick={() => setActiveTab("apartments")}
              className={
                activeTab === "apartments"
                  ? styles.interactiveMapCloropleth__activeTab
                  : ""
              }
            >
              {t("calculator.map.apartments")}
            </button>
            <button
              onClick={() => setActiveTab("educationAndHealth")}
              className={
                activeTab === "educationAndHealth"
                  ? styles.interactiveMapCloropleth__activeTab
                  : ""
              }
            >
              {t("calculator.map.educationAndHealth")}
            </button>
            <button
              onClick={() => setActiveTab("mobility")}
              className={
                activeTab === "mobility"
                  ? styles.interactiveMapCloropleth__activeTab
                  : ""
              }
            >
              {t("calculator.map.mobility")}
            </button>
            <button
              onClick={() => setActiveTab("socialScore")}
              className={
                activeTab === "socialScore"
                  ? styles.interactiveMapCloropleth__activeTab
                  : ""
              }
            >
              {t("calculator.map.socialScore")}
            </button>
          </div>

          <div className={styles.interactiveMapCloropleth__mapWrapper}>
            <object
              type="image/svg+xml"
              data={chSvg}
              className={styles.interactiveMapCloropleth__mapSvg}
              onLoad={handleSvgLoad}
            />
            {hoveredCantonId && (
              <div
                className={styles.interactiveMapCloropleth__tooltip}
                style={{
                  top: `${tooltipPosition.y}px`,
                  left: `${tooltipPosition.x}px`,
                }}
              >
                {cantons.find((c) => c.cantonId === hoveredCantonId)
                  ?.cantonName || hoveredCantonId}{" "}
                {activeTab === "apartmentPrice" &&
                  `CHF ${(
                    cantons.find((c) => c.cantonId === hoveredCantonId)
                      ?.apartmentPrice || 0
                  ).toLocaleString()}`}
                {activeTab === "socialScore" &&
                  `${
                    cantons.find((c) => c.cantonId === hoveredCantonId)
                      ?.socialScore
                  }`}
              </div>
            )}
          </div>

          <div className={styles.interactiveMapCloropleth__legend}>
            {selectedCantonId && (
              <div className={styles.interactiveMapCloropleth__selectedLegend}>
                <p>
                  <span
                    style={{
                      display: "inline-block",
                      width: "20px",
                      height: "20px",
                      backgroundColor: "#fff", // Cambia si es necesario
                      marginRight: "8px",
                      border: "1px solid #000",
                    }}
                  ></span>
                  {t("calculator.map.selectedCanton")}
                </p>
              </div>
            )}

            {/* <div className={styles.interactiveMapCloropleth__gradient}></div>
            <div className={styles.interactiveMapCloropleth__labels}>
              <span>Low</span>
              <span>High</span>
                  </div>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChoroplethMap;
