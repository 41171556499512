//formatNumberThousands.tsx

/**
 * Formats a number with thousand separators and up to two decimal places.
 * If the input is undefined or not a valid number, it returns an empty string.
 * 
 * @param num - The number to format, can be undefined.
 * @returns A string with the formatted number or an empty string.
 */

const formatNumberThousands = (num: number | undefined) => {
  if (num === undefined || isNaN(num)) return "";
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(num);
};

export default formatNumberThousands;
