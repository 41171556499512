// Home.tsx
import React from "react";
import IndividualsTextImage from "./Individuals components/IndividualsTextImage";
import IndividualsVideoText from "./Individuals components/IndividualsVideoText";
import FAQ from "./Individuals components/FAQ";
import IndividualsContacttImage from "./Individuals components/IndividualsContactImage";
import IndividualsContactImage from "./Individuals components/IndividualsContactImage";

const Individuals = () => {
  return (
    <div>
      <IndividualsContactImage />
      <IndividualsTextImage />
      <IndividualsVideoText />
      <FAQ/>
    </div>
  );
};

export default Individuals;

