import React, { useState, useEffect, useRef } from "react";
import styles from "../../styles/Calculator public styles/CostCalculatorNet.module.css";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieLabelRenderProps,
} from "recharts";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBus,
  faUtensils,
  faHeartbeat,
  faBeer,
  faCartShopping,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import CostCalculatorResults from "./CostCalculatorGraph";
import formatNumberThousands from "../Common/Formatting/FormatNumberThousands";

interface Expenses {
  apartmentCost: number;
  transportation: number;
  food: number;
  healthInsuranceCost: number;
  entertainment: number;
  otherExpenses?: number;
}

interface CostOfLivingCalculatorProps {
  netIncome: number;
  familyMembers: number;
  apartmentCost: number;
  healthInsuranceCost: number;
  canton: string;
  community: string;
}

const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: any[];
}) => {
  if (active && payload && payload.length) {
    const { name, value } = payload[0].payload;
    return (
      <div className={styles.costCalculatorNet__customTooltip}>
        <p className={styles.costCalculatorNet__tooltipName}>{name}</p>
        <p className={styles.costCalculatorNet__tooltipValue}>
          CHF
          {formatNumberThousands(Number(Math.round(value)))}
        </p>
      </div>
    );
  }

  return null;
};

const CostOfLivingCalculator: React.FC<CostOfLivingCalculatorProps> = ({
  netIncome,
  familyMembers,
  apartmentCost,
  healthInsuranceCost,
  canton,
  community,
}) => {
  const { t } = useTranslation("global");

  const [period, setPeriod] = useState<"monthly" | "annual">("monthly");

  const [income, setIncome] = useState<number>(netIncome / 12);

  const selectedCanton = canton;
  const selectedCommunity = community;

  const [familySize, setFamilySize] = useState<number>(familyMembers);

  const [expenses, setExpenses] = useState<Expenses>({
    apartmentCost: apartmentCost / 12,
    transportation: 100 * (1 + (familyMembers-1)/2),
    food: 500 * (1 + (familyMembers-1)/2),
    healthInsuranceCost: healthInsuranceCost,
    entertainment: 300 * (1 + (familyMembers-1)/2),
    otherExpenses: 500 * (1 + (familyMembers-1)/2),
  });

  const [totalExpenses, setTotalExpenses] = useState<number>(0);
  const [balance, setBalance] = useState<number>(0);
  const [isCalculated, setIsCalculated] = useState<boolean>(false);
  const [disposableIncomePercentage, setDisposableIncomePercentage] =
    useState<number>(0);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const prevPeriodRef = useRef<"monthly" | "annual">(period);

  const round = (num: number) => Math.round(num);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    const numericValue = Number(value.replace(/,/g, ""));

    if (name !== "familySize" && name !== "income") {
      setExpenses({
        ...expenses,
        [name]: numericValue >= 0 ? numericValue : 0,
      });
      setIsCalculated(false);
    }
  };

  const handlePeriodChange = (newPeriod: "monthly" | "annual") => {
    if (prevPeriodRef.current === "monthly" && newPeriod === "annual") {
      setExpenses((prevExpenses) => ({
        apartmentCost: round(prevExpenses.apartmentCost * 12),
        transportation: round(prevExpenses.transportation * 12),
        food: round(prevExpenses.food * 12),
        healthInsuranceCost: round(prevExpenses.healthInsuranceCost * 12),
        entertainment: round(prevExpenses.entertainment * 12),
        otherExpenses: prevExpenses.otherExpenses
          ? round(prevExpenses.otherExpenses * 12)
          : undefined,
      }));
      setIncome((prevIncome) => round(prevIncome * 12));
    } else if (prevPeriodRef.current === "annual" && newPeriod === "monthly") {
      setExpenses((prevExpenses) => ({
        apartmentCost: round(prevExpenses.apartmentCost / 12),
        transportation: round(prevExpenses.transportation / 12),
        food: round(prevExpenses.food / 12),
        healthInsuranceCost: round(prevExpenses.healthInsuranceCost / 12),
        entertainment: round(prevExpenses.entertainment / 12),
        otherExpenses: prevExpenses.otherExpenses
          ? round(prevExpenses.otherExpenses / 12)
          : undefined,
      }));
      setIncome((prevIncome) => round(prevIncome / 12));
    }
    setPeriod(newPeriod);
    prevPeriodRef.current = newPeriod;
    setIsCalculated(false);
  };

  const renderLabel = (props: PieLabelRenderProps) => {
    const { name, percent, x, y } = props;
    const safePercent = percent ?? 0;

    return (
      <text
        x={x}
        y={y}
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={
          windowWidth < 600 ? "8" : "10"
        } /* Tamaño de fuente adaptativo */
        fill="#333"
      >
        {`${name}: ${Math.round(safePercent * 100)}%`}
      </text>
    );
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const calculateCostOfLiving = () => {
    const total = Object.entries(expenses).reduce((acc, [key, value]) => {
      if (value > 0) {
        return acc + value;
      }
      return acc;
    }, 0);
    setTotalExpenses(total);

    // Calcula el balance correctamente
    const calculatedBalance = income - total;
    setBalance(calculatedBalance);

    const disposablePercentage =
      income > 0 ? (calculatedBalance / income) * 100 : 0;
    setDisposableIncomePercentage(disposablePercentage);

    if (total > 0) {
      setIsCalculated(true);
    } else {
      setIsCalculated(false);
    }
  };

  useEffect(() => {
    calculateCostOfLiving();
  }, []);


    const data = Object.entries(expenses)
  .filter(([_, value]) => value > 0)
  .map(([key, value]) => ({
    name: t(`calculator.net.${key}LabelChart`), // Usar la clave de traducción correspondiente
    value,
  }));



  return (
    <div className={styles.costCalculatorNet__componentCalculator}>
      <div className={styles.costCalculatorNet__calculatorContainer}>
        <form
          className={styles.costCalculatorNet__calculatorForm}
          onSubmit={(e) => {
            e.preventDefault();
            calculateCostOfLiving();
          }}
        >
          <div className={styles.costCalculatorNet__periodSelector}>
            <label>
              <input
                type="radio"
                name="period"
                value="monthly"
                checked={period === "monthly"}
                onChange={() => handlePeriodChange("monthly")}
              />
              {t("calculator.net.monthly")}
            </label>
            <label>
              <input
                type="radio"
                name="period"
                value="annual"
                checked={period === "annual"}
                onChange={() => handlePeriodChange("annual")}
              />
              {t("calculator.net.annual")}
            </label>
          </div>

          {/* Input Fields */}
          <div className={styles.costCalculatorNet__topInputs}>
            <div className={styles.costCalculatorNet__inputGroup}>
              <label htmlFor="income">{t("calculator.net.incomeLabel")}</label>
              <input
                type="text"
                id="income"
                name="income"
                value={formatNumberThousands(Number(income))}
                onChange={handleInputChange}
                min="0"
                placeholder={t("calculator.net.selectOptionPlaceholder")}
                className={styles.costCalculatorNet__input}
              />
            </div>

            <div className={styles.costCalculatorNet__inputGroup}>
              <label htmlFor="familySize">
                {t("calculator.net.familySizeLabel")}
              </label>
              <input
                type="text"
                id="familySize"
                name="familySize"
                value={familySize}
                onChange={handleInputChange}
                min="1"
                required
                placeholder=""
                className={styles.costCalculatorNet__input}
              />
            </div>
          </div>

          {/*<p className={styles.costCalculatorNet__guideComment}>
            {t("calculator.net.guideLargeCities")}
        </p>*/}

          <div className={styles.costCalculatorNet__expensesSection}>
            <div className={styles.costCalculatorNet__expenseItem}>
              <FontAwesomeIcon
                icon={faHome}
                className={styles.costCalculatorNet__icon}
              />
              <div className={styles.costCalculatorNet__expenseDetails}>
                <label
                  htmlFor="apartmentCost"
                  className={styles.costCalculatorNet__label}
                >
                  {t("calculator.net.apartmentCostLabel")}
                  <div className={styles.costCalculatorNet__tooltipContainer}>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className={styles.costCalculatorNet__infoIcon}
                    />
                    <div className={styles.costCalculatorNet__tooltip}>
                      {t("calculator.net.apartmentCostTooltip")}
                    </div>
                  </div>
                </label>
                <div className={styles.costCalculatorNet__selectInput}>
                  <input
                    type="text"
                    id="apartmentCost"
                    name="apartmentCost"
                    value={formatNumberThousands(expenses.apartmentCost)}
                    onChange={handleInputChange}
                    min="0"
                    placeholder={t("calculator.net.selectOptionPlaceholder")}
                    className={styles.costCalculatorNet__input}
                  />
                </div>
              </div>
            </div>

            <div className={styles.costCalculatorNet__expenseItem}>
              <FontAwesomeIcon
                icon={faHeartbeat}
                className={styles.costCalculatorNet__icon}
              />
              <div className={styles.costCalculatorNet__expenseDetails}>
                <label htmlFor="healthInsuranceCost">
                  {t("calculator.net.healthInsuranceCostLabel")}
                </label>
                <div className={styles.costCalculatorNet__selectInput}>
                  <input
                    type="text"
                    id="healthInsuranceCost"
                    name="healthInsuranceCost"
                    value={formatNumberThousands(expenses.healthInsuranceCost)}
                    onChange={handleInputChange}
                    min="0"
                    placeholder={t("calculator.net.selectOptionPlaceholder")}
                    className={styles.costCalculatorNet__input}
                  />
                </div>
              </div>
            </div>

            <div className={styles.costCalculatorNet__expenseItem}>
              <FontAwesomeIcon
                icon={faBus}
                className={styles.costCalculatorNet__icon}
              />
              <div className={styles.costCalculatorNet__expenseDetails}>
                <label
                  htmlFor="transportation"
                  className={styles.costCalculatorNet__label}
                >
                  {t("calculator.net.transportationLabel")}
                  <div className={styles.costCalculatorNet__tooltipContainer}>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className={styles.costCalculatorNet__infoIcon}
                    />
                    <div className={styles.costCalculatorNet__tooltip}>
                      {t("calculator.net.transportationTooltip")}
                    </div>
                  </div>
                </label>
                <div className={styles.costCalculatorNet__selectInput}>
                  <input
                    type="text"
                    id="transportation"
                    name="transportation"
                    value={formatNumberThousands(expenses.transportation)}
                    onChange={handleInputChange}
                    min="0"
                    placeholder={t("calculator.net.selectOptionPlaceholder")}
                    className={styles.costCalculatorNet__input}
                  />
                </div>
              </div>
            </div>

            <div className={styles.costCalculatorNet__expenseItem}>
              <FontAwesomeIcon
                icon={faUtensils}
                className={styles.costCalculatorNet__icon}
              />
              <div className={styles.costCalculatorNet__expenseDetails}>
                <label
                  htmlFor="food"
                  className={styles.costCalculatorNet__label}
                >
                  {t("calculator.net.foodLabel")}
                  <div className={styles.costCalculatorNet__tooltipContainer}>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className={styles.costCalculatorNet__infoIcon}
                    />
                    <div className={styles.costCalculatorNet__tooltip}>
                      {t("calculator.net.foodTooltip")}
                    </div>
                  </div>
                </label>
                <div className={styles.costCalculatorNet__selectInput}>
                  <input
                    type="text"
                    id="food"
                    name="food"
                    value={formatNumberThousands(expenses.food)}
                    onChange={handleInputChange}
                    min="0"
                    placeholder={t("calculator.net.selectOptionPlaceholder")}
                    className={styles.costCalculatorNet__input}
                  />
                </div>
              </div>
            </div>

            <div className={styles.costCalculatorNet__expenseItem}>
              <FontAwesomeIcon
                icon={faBeer}
                className={styles.costCalculatorNet__icon}
              />
              <div className={styles.costCalculatorNet__expenseDetails}>
                <label
                  htmlFor="entertainment"
                  className={styles.costCalculatorNet__label}
                >
                  {t("calculator.net.entertainmentLabel")}
                  <div className={styles.costCalculatorNet__tooltipContainer}>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className={styles.costCalculatorNet__infoIcon}
                    />
                    <div className={styles.costCalculatorNet__tooltip}>
                      {t("calculator.net.entertainmentTooltip")}
                    </div>
                  </div>
                </label>
                <div className={styles.costCalculatorNet__selectInput}>
                  <input
                    type="text"
                    id="entertainment"
                    name="entertainment"
                    value={formatNumberThousands(expenses.entertainment)}
                    onChange={handleInputChange}
                    min="0"
                    placeholder={t("calculator.net.selectOptionPlaceholder")}
                    className={styles.costCalculatorNet__input}
                  />
                </div>
              </div>
            </div>

            <div className={styles.costCalculatorNet__expenseItem}>
              <FontAwesomeIcon
                icon={faCartShopping}
                className={styles.costCalculatorNet__icon}
              />
              <div className={styles.costCalculatorNet__expenseDetails}>
                <label
                  htmlFor="otherExpenses"
                  className={styles.costCalculatorNet__label}
                >
                  {t("calculator.net.otherExpensesLabel")}
                  <div className={styles.costCalculatorNet__tooltipContainer}>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className={styles.costCalculatorNet__infoIcon}
                    />
                    <div className={styles.costCalculatorNet__tooltip}>
                      {t("calculator.net.otherExpensesTooltip")}
                    </div>
                  </div>
                </label>
                <div className={styles.costCalculatorNet__selectInput}>
                  <input
                    type="text"
                    id="otherExpenses"
                    name="otherExpenses"
                    value={
                      expenses.otherExpenses !== undefined
                        ? formatNumberThousands(expenses.otherExpenses)
                        : ""
                    }
                    onChange={handleInputChange}
                    min="0"
                    placeholder={t("calculator.net.selectOptionPlaceholder")}
                    className={styles.costCalculatorNet__input}
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className={styles.costCalculatorNet__calculateButton}
          >
            {t("calculator.net.calculateButton")}
          </button>
        </form>
        <CostCalculatorResults
          isCalculated={isCalculated}
          canton={canton}
          community={community}
          totalExpenses={totalExpenses}
          balance={balance}
          income={income}
          disposableIncomePercentage={disposableIncomePercentage}
          data={data}
          renderLabel={renderLabel}
          CustomTooltip={CustomTooltip}
          windowWidth={windowWidth}
        />
      </div>
      <div className={styles.costCalculatorNet__chartContainer}>
        <p>
          {t("calculator.net.note1")}{" "}
          <a
            href="/registration-individuals"
            target="_blank"
            style={{ color: "#428c9f", textDecoration: "underline" }}
          >
            {t("calculator.net.note-link")}
          </a>{" "}
          {t("calculator.net.note2")} {" "}
          <a
            href="/contact-us"
            target="_blank"
            style={{ color: "#428c9f", textDecoration: "underline" }}
          >
            {t("calculator.net.note2-link")}
          </a>{" "}
          {t("calculator.net.note3")}
        </p>
      </div>
    </div>
  );
};

export default CostOfLivingCalculator;
