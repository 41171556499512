import React, { useEffect, useState } from "react";
import Select from "react-select";
import styles from "../../styles/Calculator public styles/CostCalculatorGross.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBus,
  faUtensils,
  faHeartbeat,
  faBeer,
  faCartShopping,
  faUser,
  faDollarSign,
  faRing,
  faPlaceOfWorship,
  faChild,
  faUserGraduate,
  faUserTie,
  faBuilding,
  faHouseUser,
  faRulerCombined,
  faBank,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import formatNumberThousands from "../Common/Formatting/FormatNumberThousands";

interface CostCalculatorGrossProps {
  onCalculateResults: (results: {
    tax: number | null;
    healthInsuranceCost: number | null;
    apartmentCost: number | null;
    netIncome: number | null;
    familyMembers: number;
  }) => void;
  onNextTab: () => void;
  onLocationChange: (canton: string, community: string) => void;
}

interface Canton {
  cantonId: string;
  cantonName: string;
}

interface Community {
  cantonId: string;
  communityName: string;
}

const CostCalculatorGross: React.FC<CostCalculatorGrossProps> = ({
  onCalculateResults,
  onNextTab,
  onLocationChange,
}) => {
  const { t } = useTranslation("global");

  const [formData, setFormData] = useState({
    grossSalary: "",
    age: "",
    threshold: "No",
    isMarried: "",
    religion: "",
    children: "",
    youngAdults: "",
    adults: "",
    canton: "",
    community: "",
    rooms: "",
    size: "",
  });

  const [cantons, setCantons] = useState<Canton[]>([]);
  const [communities, setCommunities] = useState<Community[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCantons = async () => {
      try {
        //const response = await fetch("http://localhost:8080/datasets/cantons/");
        const response = await fetch(
          "https://api.relotu.com/datasets/cantons/"
        );
        const data = await response.json();
        console.log("Cantons data received:", data); // Añadir este log para verificar los datos
        setCantons(data);
        console.log(cantons);
      } catch (error) {
        console.log("Error fetching cantons:", error);
      }
    };
    fetchCantons();
  }, []);

  // Fetch para las comunidades basadas en el cantón seleccionado
  useEffect(() => {
    const fetchCommunities = async () => {
      if (!formData.canton) return; // Si no hay cantón seleccionado, no hacemos nada
      try {
        const response = await fetch(
          //`http://localhost:8080/datasets/communities/${formData.canton}`
          `https://api.relotu.com/datasets/communities/${formData.canton}`
        );
        const data = await response.json();
        setCommunities(data);
      } catch (error) {
        console.log("Error fetching communities:", error);
      }
    };
    fetchCommunities();
  }, [formData.canton]); // Solo se ejecuta cuando cambia el cantón seleccionado

  const validateForm = () => {
    if (!formData.grossSalary || Number(formData.grossSalary) <= 0) {
      setError("Gross Salary must be greater than or equal to 0.");
      return false;
    }
    {
      /*if (!formData.age || Number(formData.age) <= 18) {
      setError("Age must be greater than or equal 18.");
      return false;
    }*/
    }
    if (!formData.isMarried) {
      setError(t("calculator.gross.isMarriedRequired"));
      return false;
    }
    if (!formData.religion) {
      setError(t("calculator.gross.religionRequired"));
      return false;
    }
    if (formData.children === "") {
      setError(t("calculator.gross.childrenRequired"));
      return false;
    }
    if (formData.youngAdults === "") {
      setError(t("calculator.gross.youngAdultsRequired"));
      return false;
    }
    if (formData.adults === "") {
      setError(t("calculator.gross.adultsRequired"));
      return false;
    }
    if (!formData.canton) {
      setError(t("calculator.gross.cantonRequired"));
      return false;
    }
    if (!formData.community) {
      setError(t("calculator.gross.communityRequired"));
      return false;
    }
    if (!formData.rooms || Number(formData.rooms) <= 0) {
      setError(t("calculator.gross.roomsRequired"));
      return false;
    }
    if (!formData.size || Number(formData.size) <= 0) {
      setError(t("calculator.gross.sizeRequired"));
      return false;
    }
    return true;
  };

  const handleInputChange = (field: string, value: any) => {
    if (field === "grossSalary") {
      const numericValue = Number(value.replace(/,/g, ""));
      if (!isNaN(numericValue) && numericValue >= 0) {
        setFormData((prev) => ({ ...prev, [field]: numericValue.toString() }));
      }
    } else {
      setFormData((prev) => ({ ...prev, [field]: value }));
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleCalculate = async () => {
    if (!validateForm()) {
      return;
    }
    setError(null);
    setIsLoading(true);
    try {
      const familyMembers =
        Number(formData.adults || 0) +
        Number(formData.youngAdults || 0) +
        Number(formData.children || 0);

      const healthInsuranceData = {
        community: formData.community,
        children: formData.children || 0,
        youngAdults: formData.youngAdults || 0,
        adults: formData.adults || 0,
      };

      const healthInsuranceResponse = await fetch(
        //"http://localhost:8080/calculator/calculateHealthInsuranceCost",
        "https://api.relotu.com/calculator/calculateHealthInsuranceCost",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(healthInsuranceData),
        }
      );
      const healthInsuranceResult = await healthInsuranceResponse.json();

      const apartmentData = {
        canton: formData.canton,
        rooms: formData.rooms,
        size: Number(formData.size || 0),
      };
      console.log(apartmentData);

      const apartmentResponse = await fetch(
        //"http://localhost:8080/calculator/calculateApartmentCost",
        "https://api.relotu.com/calculator/calculateApartmentCost",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(apartmentData),
        }
      );
      const apartmentResult = await apartmentResponse.json();
      console.log(apartmentResult);

      const taxID = `${formData.isMarried}${formData.religion}${
        formData.children || 0
      }${formData.canton}${formData.community}`;
      const taxData = {
        id: taxID,
        married: formData.isMarried,
        religion: formData.religion,
        children: String(formData.children || 0),
        canton: formData.canton,
        community: formData.community,
        grossSalary: formData.grossSalary || 0,
        threshold: formData.threshold,
      };
      console.log(taxData);

      const taxCostResponse = await fetch(
        "https://api.relotu.com/calculator/calculateTaxCost",
        //"http://localhost:8080/calculator/calculateTaxCost",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(taxData),
        }
      );
      console.log(taxData);
      const taxResult = await taxCostResponse.json();

      // Redondear ingresos
      const grossIncome = Math.round(Number(formData.grossSalary) || 0);

      const calculatedTax = Math.round(
        taxResult.taxCost.AHVIVEOmonthly +
          taxResult.taxCost.ALVmonthly +
          taxResult.taxCost.UVGmonthly +
          taxResult.taxCost.taxCost
      );

      const yearlyTax =
        formData.threshold === "No" && grossIncome < 120000
          ? calculatedTax * 12
          : calculatedTax;

      const healthInsuranceCost = Math.round(
        healthInsuranceResult.healthInsuranceCost || 0
      );
      const apartmentCost = Math.round(apartmentResult.apartmentCost || 0);

      const netIncome = Math.round(grossIncome - yearlyTax);
      const selectedCanton = formData.canton;
      const selectedCommunity = formData.community;

      onCalculateResults({
        tax: yearlyTax,
        healthInsuranceCost,
        apartmentCost,
        netIncome,
        familyMembers,
      });

      onNextTab();
    } catch (error) {
      console.error("Error al calcular:", error);
      alert(
        "Data combination not found. Please reach out for more detail advice."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCantonChange = (selectedOption: any) => {
    const selectedCanton = selectedOption ? selectedOption.value : "";
    setFormData((prev) => ({
      ...prev,
      canton: selectedCanton,
      community: "", // Reset community cuando cambia el canton
    }));
    onLocationChange(selectedCanton, ""); // Notifica el cambio de canton
  };

  const handleCommunityChange = (selectedOption: any) => {
    const selectedCommunity = selectedOption ? selectedOption.value : "";
    setFormData((prev) => ({
      ...prev,
      community: selectedCommunity,
    }));
    onLocationChange(formData.canton, selectedCommunity); // Notifica el cambio de community
  };

  const cantonOptions = cantons.map((canton) => ({
    value: canton.cantonId,
    label: canton.cantonName,
  }));

  const communityOptions = communities.map((community) => ({
    value: community.communityName,
    label: community.communityName,
  }));

  return (
    <div className={styles.costCalculatorGross__container}>
      {/* Cuadro de instrucciones */}
      <h2></h2>
      <div className={styles.costCalculatorGross__instructions}>
        <h3> {t("calculator.gross.titleBox")} </h3>
        <p>{t("calculator.gross.paragraph1")}</p>
        <ul>
          <li>
            <b>{t("calculator.gross.listItem1-bold")}</b>
            {t("calculator.gross.listItem1")}
          </li>
          <li>
            <b>{t("calculator.gross.listItem2-bold")} </b>
            {t("calculator.gross.listItem2")}
          </li>
          <li>
            <b>{t("calculator.gross.listItem3-1-bold")}</b>{" "}
            <a
              href="/cost-calculator-map"
              target="_blank"
              style={{ color: "#428c9f", textDecoration: "underline" }}
            >
              {t("calculator.gross.listItem3-link")}
            </a>{" "}
            {t("calculator.gross.listItem3-2")}
          </li>
        </ul>
        <p>
          <b>{t("calculator.gross.paragraph2")}</b>
        </p>
        <p>
          {t("calculator.gross.paragraph3-1")}{" "}
          <a
            href="/registration-individuals"
            target="_blank"
            style={{ color: "#428c9f", textDecoration: "underline" }}
          >
            {t("calculator.gross.paragraph3-link1")}
          </a>{" "}
          {t("calculator.gross.paragraph3-2")}{" "}
          <a
            href="/contact-us"
            target="_blank"
            style={{ color: "#428c9f", textDecoration: "underline" }}
          >
            {t("calculator.gross.paragraph3-link2")}
          </a>{" "}
          {t("calculator.gross.paragraph3-3")}
        </p>
      </div>

      <div className={styles.costCalculatorGross__calculatorAndButton}>
        <div className={styles.costCalculatorGross__calculator}>
          <form className={styles.costCalculatorGross__form}>
            {/* Gross Salary */}
            <div className={styles.costCalculatorGross__inputGroup}>
              <FontAwesomeIcon
                icon={faDollarSign}
                className={styles.costCalculatorGross__icon}
              />
              <label className={styles.costCalculatorGross__label}>
                {t("calculator.gross.grossSalary")}
              </label>
              <input
                type="text"
                placeholder="e.g. 150,000"
                value={
                  formData.grossSalary === ""
                    ? ""
                    : formatNumberThousands(Number(formData.grossSalary))
                }
                onChange={(e) =>
                  handleInputChange("grossSalary", e.target.value)
                }
                className={styles.costCalculatorGross__input}
              />
            </div>

            {/* Age */}
            <div className={styles.costCalculatorGross__inputGroup}>
              <FontAwesomeIcon
                icon={faUser}
                className={styles.costCalculatorGross__icon}
              />
              <label className={styles.costCalculatorGross__label}>
                {t("calculator.gross.age")}
              </label>
              <input
                type="number"
                placeholder="e.g. 33"
                value={formData.age}
                onChange={(e) => handleInputChange("age", e.target.value)}
                className={styles.costCalculatorGross__input}
              />
            </div>

            {/* Wealth */}
            <div className={styles.costCalculatorGross__inputGroup}>
              <FontAwesomeIcon
                icon={faBank}
                className={styles.costCalculatorGross__icon}
              />
              <label
                htmlFor="threshold"
                className={styles.costCalculatorGross__label}
              >
                {t("calculator.gross.threshold")}
              </label>
              <div className={styles.costCalculatorGross__toggleWrapper}>
                <input
                  type="checkbox"
                  id="threshold"
                  checked={formData.threshold === "Yes"}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      threshold: e.target.checked ? "Yes" : "No",
                    }))
                  }
                  className={styles.costCalculatorGross__toggle}
                />
                {/* Texto dentro del toggle */}
                <div className={styles.costCalculatorGross__toggleTextWrapper}>
                  <span className={styles.costCalculatorGross__toggleTextLeft}>
                    {t("calculator.gross.yes")}
                  </span>
                  <span className={styles.costCalculatorGross__toggleTextRight}>
                    {t("calculator.gross.no")}
                  </span>
                </div>
              </div>
            </div>

            {/* Married */}
            <div className={styles.costCalculatorGross__inputGroup}>
              <FontAwesomeIcon
                icon={faRing}
                className={styles.costCalculatorGross__icon}
              />
              <label
                htmlFor="isMarried"
                className={styles.costCalculatorGross__label}
              >
                {t("calculator.gross.married")}
              </label>
              <select
                id="isMarried"
                value={formData.isMarried}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    isMarried: e.target.value,
                  }))
                }
                className={`${styles.costCalculatorGross__select} ${
                  formData.isMarried === "" ? styles.placeholder : ""
                }`}
              >
                <option value="" disabled hidden>
                  {t("calculator.gross.select")}
                </option>
                <option value="No">{t("calculator.gross.no")}</option>

                {formData.threshold === "No" &&
                Number(formData.grossSalary) < 120000 ? (
                  <>
                    <option value="YesOneSalary">
                      {t("calculator.gross.yesOneSalary")}
                    </option>
                    <option value="YesTwoSalaries">
                      {t("calculator.gross.yesTwoSalaries")}
                    </option>
                  </>
                ) : (
                  <>
                    <option value="No">
                      {t("calculator.gross.yesOneSalary")}
                    </option>
                    <option value="No">
                      {t("calculator.gross.yesTwoSalaries")}
                    </option>
                  </>
                )}
              </select>
            </div>

            {/* Religion */}
            <div className={styles.costCalculatorGross__inputGroup}>
              <FontAwesomeIcon
                icon={faPlaceOfWorship}
                className={styles.costCalculatorGross__icon}
              />
              <label
                htmlFor="religion"
                className={styles.costCalculatorGross__label}
              >
                {t("calculator.gross.religion")}
              </label>
              <select
                id="religion"
                value={formData.religion}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, religion: e.target.value }))
                }
                className={`${styles.costCalculatorGross__select} ${
                  formData.religion === "select" || formData.religion === ""
                    ? styles.placeholder
                    : ""
                }`}
              >
                <option value="" disabled hidden>
                  {t("calculator.gross.select")}
                </option>{" "}
                <option value="None">{t("calculator.gross.none")}</option>
                <option value="Yes">{t("calculator.gross.catholic")}</option>
                <option value="Yes">{t("calculator.gross.protestant")}</option>
                <option value="Yes">{t("calculator.gross.other")}</option>
              </select>
            </div>

            {/* Children */}
            <div className={styles.costCalculatorGross__inputGroup}>
              <FontAwesomeIcon
                icon={faChild}
                className={styles.costCalculatorGross__icon}
              />
              <label className={styles.costCalculatorGross__label}>
                {t("calculator.gross.children1")}
              </label>
              <select
                value={formData.children}
                onChange={(e) =>
                  handleInputChange("children", parseInt(e.target.value))
                }
                className={`${styles.costCalculatorGross__select} ${
                  formData.children === "" ? styles.placeholder : ""
                }`}
              >
                <option value="" disabled hidden>
                  {t("calculator.gross.select")}
                </option>{" "}
                {Array.from(Array(7), (_, number) => (
                  <option key={number} value={number}>
                    {number}
                  </option>
                ))}
              </select>
            </div>

            {/* Young Adults */}
            <div className={styles.costCalculatorGross__inputGroup}>
              <FontAwesomeIcon
                icon={faUserGraduate}
                className={styles.costCalculatorGross__icon}
              />
              <label className={styles.costCalculatorGross__label}>
                {t("calculator.gross.children2")}
              </label>
              <select
                value={formData.youngAdults}
                onChange={(e) =>
                  handleInputChange("youngAdults", parseInt(e.target.value))
                }
                className={`${styles.costCalculatorGross__select} ${
                  formData.youngAdults === "" ? styles.placeholder : ""
                }`}
              >
                <option value="" disabled hidden>
                  {t("calculator.gross.select")}
                </option>{" "}
                {Array.from(Array(7), (_, number) => (
                  <option key={number} value={number}>
                    {number}
                  </option>
                ))}
              </select>
            </div>

            {/* Adults */}
            <div className={styles.costCalculatorGross__inputGroup}>
              <FontAwesomeIcon
                icon={faUserTie}
                className={styles.costCalculatorGross__icon}
              />
              <label className={styles.costCalculatorGross__label}>
                {t("calculator.gross.children3")}
              </label>
              <select
                value={formData.adults}
                onChange={(e) =>
                  handleInputChange("adults", parseInt(e.target.value))
                }
                className={`${styles.costCalculatorGross__select} ${
                  formData.adults === "" ? styles.placeholder : ""
                }`}
              >
                <option value="" disabled hidden>
                  {t("calculator.gross.select")}
                </option>{" "}
                {Array.from({ length: 6 }, (_, index) => index + 1).map(
                  (number) => (
                    <option key={number} value={number}>
                      {number}
                    </option>
                  )
                )}
              </select>
            </div>

            {/* Canton */}
            <div className={styles.costCalculatorGross__inputGroup}>
              <FontAwesomeIcon
                icon={faBuilding}
                className={styles.costCalculatorGross__icon}
              />
              <label className={styles.costCalculatorGross__label}>
                {t("calculator.gross.canton")}
              </label>
              <Select
                options={cantonOptions}
                value={cantonOptions.find(
                  (option) => option.value === formData.canton
                )}
                onChange={handleCantonChange}
                placeholder="Search"
                isClearable
                isSearchable
                styles={{
                  control: (base, state) => ({
                    ...base,
                    width: 160,
                    marginLeft: "10px",
                    borderColor: state.isFocused ? "#428c9f" : "#dddddd",
                    boxShadow: state.isFocused
                      ? `0 0 0 1px #428c9f`
                      : undefined,
                    "&:hover": {
                      borderColor: "#428c9f",
                    },
                    "@media (max-width: 768px)": {
                      maxWidth: "90%",
                      width: "140px",
                    },
                  }),
                }}
              />
            </div>

            {/* Community */}
            <div className={styles.costCalculatorGross__inputGroup}>
              <FontAwesomeIcon
                icon={faHouseUser}
                className={styles.costCalculatorGross__icon}
              />
              <label className={styles.costCalculatorGross__label}>
                {t("calculator.gross.community")}
              </label>
              <Select
                options={communityOptions}
                value={communityOptions.find(
                  (option) => option.value === formData.community
                )}
                onChange={handleCommunityChange}
                placeholder="Search"
                isClearable
                isSearchable
                styles={{
                  control: (base, state) => ({
                    ...base,
                    width: 160,
                    borderColor: state.isFocused ? "#428c9f" : "#dddddd",
                    boxShadow: state.isFocused
                      ? `0 0 0 1px #428c9f`
                      : undefined,
                    "&:hover": {
                      borderColor: "#428c9f",
                    },
                    "@media (max-width: 768px)": {
                      maxWidth: "90%",
                      width: "150px",
                    },
                  }),
                }}
              />
            </div>

            {/* Apartment Size (Rooms) */}
            <div className={styles.costCalculatorGross__inputGroup}>
              <FontAwesomeIcon
                icon={faBuilding}
                className={styles.costCalculatorGross__icon}
              />
              <label className={styles.costCalculatorGross__label}>
                {t("calculator.gross.rooms")}
              </label>
              <select
                value={formData.rooms}
                onChange={(e) => handleInputChange("rooms", e.target.value)}
                className={`${styles.costCalculatorGross__select} ${
                  formData.rooms === "" ? styles.placeholder : ""
                }`}
              >
                <option value="" disabled hidden>
                  {t("calculator.gross.select")}
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6+">6+</option>
              </select>
            </div>

            {/* Apartment Size (m2) */}
            <div className={styles.costCalculatorGross__inputGroup}>
              <FontAwesomeIcon
                icon={faRulerCombined}
                className={styles.costCalculatorGross__icon}
              />
              <label className={styles.costCalculatorGross__label}>
                {t("calculator.gross.meters")}
              </label>
              <input
                type="number"
                placeholder="e.g. 80"
                value={formData.size}
                onChange={(e) => handleInputChange("size", e.target.value)}
                className={styles.costCalculatorGross__input}
              />
            </div>
          </form>
        </div>
        <div className={styles.costCalculatorGross__buttonSection}>
          <div
            className={`${styles.costCalculatorGross__errorContainer} ${
              error ? styles.showError : ""
            }`}
          >
            {error && (
              <p className={styles.costCalculatorGross__errorMessage}>
                {error}
              </p>
            )}
          </div>
          {/* Submit Button */}
          <div className={styles.costCalculatorGross__buttonWrapper}>
            {isLoading ? (
              <div className={styles.costCalculatorGross__loadingContainer}>
                <div className={styles.costCalculatorGross__spinner}></div>
                <p className={styles.costCalculatorGross__loadingText}>
                  {t("calculator.gross.loading")}
                </p>
              </div>
            ) : (
              <button
                type="button"
                onClick={handleCalculate}
                className={styles.costCalculatorGross__button}
              >
                {t("calculator.gross.calculate")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CostCalculatorGross;
