// src/components/RegistrationCompanies.tsx

import React, { useState } from "react";
import styles from "../../styles/Authentication styles/LogIn.module.css";
import loginImage from "../../assets/loginCompanies.png";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PasswordValidation from "./ValidationInputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const RegistrationCompanies: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("global");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const [image, setImage] = useState<File | null>(null);

  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);

  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password: string) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isValidLength = password.length >= minLength;

    return (
      isValidLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSpecialChar
    );
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();


    setFieldErrors({});


    let isValid = true;
    let errors: { [key: string]: string } = {};

    if (!name.trim()) {
      errors.name = "Name is required.";
      isValid = false;
    }

    if (!email.trim()) {
      errors.email = "Email is required.";
      isValid = false;
    } else if (!validateEmail(email)) {
      errors.email = "Please enter a valid email address.";
      isValid = false;
    }

    if (!password.trim()) {
      errors.password = "Password is required.";
      isValid = false;
    } else if (!validatePassword(password)) {
      errors.password = "Password does not meet the required criteria.";
      isValid = false;
    }

    if (!confirmPassword.trim()) {
      errors.confirmPassword = "Please confirm your password.";
      isValid = false;
    } else if (password !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match.";
      isValid = false;
    }

    if (!acceptedTerms) {
      errors.acceptedTerms = "You must accept the terms and conditions.";
      isValid = false;
    }

    if (!isValid) {
      setFieldErrors(errors);
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);
    if (image) {
      formData.append("image", image);
    }
    const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';

    try {
      const response = await fetch("https://api.relotu.com/companies/", {
        method: "POST",
        body: formData,
        headers: {
          'Accept-Language': selectedLanguage,
        }
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Registration successful:", data);

    
        localStorage.setItem("userType", "company");

    
        navigate("/email-sent-confirmation");

        alert(`Registration successful! An email has been sent to ${email}.`);
      } else {
        const errorData = await response.json();
        console.error("Error during registration:", errorData);
        alert("Error during registration: " + errorData.message);
      }
    } catch (error) {
      console.error("Error during the request:", error);
      alert("There was an error during registration. Please try again.");
    }
  };

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  return (
    <div>
      <h2 className={styles.title}>
        {t("authentication.registration-company-title")}{" "}
        <span role="img" aria-label="globe" className={styles.icon}>
          🌍
        </span>
      </h2>
      <div className={styles.loginPageContainer}>
        <img src={loginImage} alt="Login" className={styles.loginImage} />
        <div className={styles.registrationContainer}>
        <h4 className={styles.subtitle}>
          {t("authentication.registration-company-subtitle")}{" "}
          <Link to="/registration-individuals" className={styles.link}>
            {t("authentication.registration-not-company-link")}
          </Link>
        </h4>
          <form onSubmit={handleSubmit}>
            {/* Campo de Nombre */}
            <div className={styles.formGroup}>
              <label htmlFor="name">
                {t("authentication.registration-company-name")}
              </label>
              <input
                type="text"
                id="name"
                placeholder="e.g. Company XYZ"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className={styles.input}
              />
              {fieldErrors.name && (
                <p className={styles.errorMessage}>{fieldErrors.name}</p>
              )}
            </div>

            {/* Campo de Email */}
            <div className={styles.formGroup}>
              <label htmlFor="email">
                {t("authentication.registration-company-email")}
              </label>
              <input
                type="email"
                id="email"
                placeholder="e.g. contact@companyxyz.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className={styles.input}
              />
              {fieldErrors.email && (
                <p className={styles.errorMessage}>{fieldErrors.email}</p>
              )}
            </div>

            {/* Campo de Contraseña con Icono de Mostrar/Ocultar */}
            <div className={styles.formGroup}>
              <label htmlFor="password" className={styles.label}>
                {t("authentication.registration-company-password")}
              </label>
              <div className={styles.inputWrapper}>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="**********"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className={styles.input}
                />
                <button
                  type="button"
                  className={styles.eyeButton}
                  onClick={toggleShowPassword}
                  aria-label={
                    showPassword
                      ? t("authentication.hide-password")
                      : t("authentication.show-password")
                  }
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
              </div>
              {/* Contenedor para PasswordValidation */}
              <div className={styles.passwordValidationContainer}>
                <PasswordValidation password={password} />
              </div>
              {fieldErrors.password && (
                <p className={styles.errorMessage}>{fieldErrors.password}</p>
              )}
            </div>

            {/* Campo de Confirmar Contraseña con Icono de Mostrar/Ocultar */}
            <div className={styles.formGroup}>
              <label htmlFor="confirm-password">
                {t("authentication.registration-company-confirm-password")}
              </label>
              <div className={styles.inputWrapper}>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirm-password"
                  placeholder="**********"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  className={styles.input}
                />
                <button
                  type="button"
                  className={styles.eyeButton}
                  onClick={toggleShowConfirmPassword}
                  aria-label={
                    showConfirmPassword
                      ? t("authentication.hide-password")
                      : t("authentication.show-password")
                  }
                >
                  <FontAwesomeIcon
                    icon={showConfirmPassword ? faEyeSlash : faEye}
                  />
                </button>
              </div>
              {fieldErrors.confirmPassword && (
                <p className={styles.errorMessage}>
                  {fieldErrors.confirmPassword}
                </p>
              )}
            </div>

            {/* Subida de Imagen */}
            <div className={styles.formGroup}>
              <label htmlFor="image">
                {t("authentication.registration-company-logo")}
              </label>
              <input
                type="file"
                id="image"
                accept="image/*"
                onChange={handleImageChange}
                className={styles.input}
              />
            </div>

            {/* Términos y Condiciones */}
            <div className={styles.termsGroup}>
              <label htmlFor="acceptedTerms" className={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  id="acceptedTerms"
                  checked={acceptedTerms}
                  onChange={(e) => setAcceptedTerms(e.target.checked)}
                  className={styles.checkbox}
                />
                <span>{t("authentication.accept-terms")}</span>{" "}
                <Link to="/terms-and-conditions" className={styles.termsLink}>
                  {t("authentication.terms-and-conditions")}
                </Link>
              </label>
              {fieldErrors.acceptedTerms && (
                <p className={styles.errorMessage}>
                  {fieldErrors.acceptedTerms}
                </p>
              )}
            </div>

            {/* Botón de Envío */}
            <button
              type="submit"
              className={styles.registrationButton}
              disabled={!acceptedTerms}
            >
              {t("authentication.registration-company-button")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegistrationCompanies;
